import React, {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { mutate } from "swr";
import { useHedgingContext } from "@/context/betaContext";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

import get from "lodash.get";
import isEmpty from "lodash.isempty";
import map from "lodash.map";
import orderBy from "lodash.orderby";

import {
  apiGetRequest,
  apiGetWithoutTokenRequest,
  apiPostRequest,
  apiPutRequest,
} from "@/lib/api";
import { apiKeys } from "@/lib/regex";
import { negativeBetaError, updatedPortfolioData } from "@/lib/formulas";
import { formatDecimalNumber } from "../lib/text";

import {
  HtmlFragment as HF,
  Icon,
  Tooltip,
  Dropdown,
  LineTitle,
  SetHedge,
  EditPortfolio,
  PerformanceRange,
  PerformanceGraph,
} from "./*";
import OnboardingModal from "./onboarding-modal";
import Notification from "./notification";
import { useRouter } from "next/router";
import { filterDataEdit } from "./edit-portfolio";
import DisableState from "./disable-state";
import { ButtonLoader, HoldingLoader, TableLoader } from "@/lib/loaders";

const BASE_CLASS = "builder-tabs";

function BuilderTabs(props) {
  const { className, title, titleInfo, tabList } = props;
  const [openEditHoldingModal, setEditHoldingModal] = useState(false);

  const { isAuthenticated, user } = useAuth0();

  const {
    isSelectPortfolio,
    isRequiredInvestment,
    handleSelectPortfolio,
    getHedgeData,
    getAllPortfolio,
    handleIsAlert,
    handleChangeEditLabel,
    selectedIndexId,
    rangeData,
    betaValue,
    hedgeTotal,
    handleEditHolding,
    handleLoaderShow,
    handleSaveHoldings,
    handleIsHoldings,
    active,
    handleActive,
    editTableData,
    editHolding,
    handleShowModal,
    handleUpdatePortfolioName,
    handleExistingNameError,
    handleUpdateDropdown,
    handleTickerCheck,
    isSelectedBeta,
    handleBlankValues,
    updateModifyHedge,
    isModifyHedge,
    getTickerBeta,
    rebalanceNew,
    editBetaValue,
    updateDropdown,
    getIndexData,
    handleGetCurrRateValue,
  } = useHedgingContext();

  const handleHoldingModal = () => {
    handleShowModal((prev) => !prev);
    setEditHoldingModal(false);
  };

  const dataCheckEdit = editHolding
    ? filterDataEdit(editTableData, isSelectedBeta)
    : [];

  const checkBlankTicker = editTableData?.find(
    (cv) => !cv.VALUE && !cv.UNITS && !!cv.TICKER
  )?.TICKER;

  const handleEditHoldingModal = async () => {
    setEditHoldingModal(true);
    if (dataCheckEdit?.length === 0 && !checkBlankTicker) {
      handleShowModal((prev) => !prev);
    } else {
      handleTickerCheck(true);
      handleBlankValues(true);
    }
    if (dataCheckEdit?.length === 0 && !checkBlankTicker) {
      handleShowModal((prev) => !prev);
    } else {
      handleTickerCheck(true);
      handleBlankValues(true);
    }
  };

  const handleEditPortfolio = async () => {
    handleLoaderShow(true);
    const totalHedgeExpo = betaValue * (rangeData / 100);
    const totalUnhedgeExpo = betaValue - totalHedgeExpo;

    const totalPortfolio = editTableData?.reduce((acc, cv) => {
      return acc + parseFloat(cv.VALUE || 0);
    }, 0);

    const holdings = editTableData
      ?.filter((val) => val.TICKER !== "")
      ?.map((val) => ({
        TICKER: val.TICKER,
        TICKER_NAME: val.TICKER_NAME,
        PRICE: val.PRICE,
        WEIGHT: val.WEIGHT.replace(/,/g, ""),
        UNITS: val.UNITS.replace(/,/g, ""),
        VALUE: val.VALUE,
      }));

    const hedgeHolding = {
      OKTA_ID: user?.sub,
      PORTFOLIO_ID: isSelectPortfolio,
    };

    const roundRequireInvestment = Number(
      (totalHedgeExpo / Math.abs(getTickerBeta)).toFixed(5)
    );

    const apiExposure =
      getHedgeData?.hedgeholding?.HEDGED_UNITS *
      getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE *
      Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

    const editBeta = editHolding ? editBetaValue : betaValue;

    const currentHedgePercentage = (apiExposure / editBeta) * 100;

    const editPercentage = editHolding
      ? currentHedgePercentage
      : Math.floor(getHedgeData?.hedgeholding?.HEDGE_PERCENT);

    const targetHedgeExposure = (editPercentage / 100) * editBeta;

    const requireHedgeHolding =
      targetHedgeExposure /
      Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

    let REQUIRED_INVESTMENT = roundRequireInvestment;

    if (rebalanceNew || editHolding) {
      REQUIRED_INVESTMENT = requireHedgeHolding;
    }

    const getCurrRate = await mutate(apiKeys["hedgeTickerListKey"], () =>
      apiGetWithoutTokenRequest(`api/getindexticker?ticker=SH`)
    );
    handleGetCurrRateValue(getCurrRate);

    const tickerData =
      getIndexData?.market_close_value ?? getCurrRate?.market_close_value;

    let rebalnceCheck;

    if (rebalanceNew || (!updateDropdown && updateModifyHedge)) {
      rebalnceCheck = getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE;
    } else {
      rebalnceCheck = tickerData;
    }

    const hedgeUnits = Number(
      (REQUIRED_INVESTMENT / rebalnceCheck).toFixed(10)
    );

    const hedgeholdingData = {
      hedgeholding: {
        OKTA_ID: user?.sub,
        PORTFOLIO_ID: isSelectPortfolio,
        HEDGE_TICKER_ID: selectedIndexId === undefined ? 1 : selectedIndexId,
        HEDGE_PERCENT: getHedgeData?.hedgeholding?.HEDGE_PERCENT,
        TOTAL_BETA_EXPOSURE: betaValue ? betaValue : 0,
        HEDGED_EXPOSURE: totalHedgeExpo ? totalHedgeExpo : totalHedgeExpo,
        UNHEDGED_EXPOSURE: totalUnhedgeExpo ? totalUnhedgeExpo : 0,
        REQUIRED_INVESTMENT: isRequiredInvestment,
        HEDGED_TOTAL: hedgeTotal,
        UNHEDGED_TOTAL: totalPortfolio,
        HEDGED_UNITS: hedgeUnits,
      },
    };

    const builderData = {
      holdings: [...holdings],
      hedgeholding: hedgeHolding,
    };

    const {
      data: addNewHolding,
      status: statusAddNewHolding,
      errors: errorsAddNewHolding,
    } = await mutate(
      apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
      () => apiPostRequest(`api/saveHoldings`, JSON.stringify(builderData)),
      { revalidate: true }
    );

    await mutate(
      apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
      apiPostRequest(`api/hedgeholdings`, JSON.stringify(hedgeholdingData)),
      { revalidate: true }
    );

    if (!isEmpty(addNewHolding) && !addNewHolding?.error) {
      await mutate(
        apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
        () =>
          apiGetRequest(
            `api/getholdings?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
          ),
        { revalidate: true }
      );

      await mutate(apiKeys["graphData"](user?.sub, isSelectPortfolio), () =>
        apiGetRequest(
          `/api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
        )
      );

      handleLoaderShow(false);
      handleIsAlert({
        open: true,
        message: "Your holdings have been updated.",
        error: false,
      });
      handleChangeEditLabel(false);
      handleIsHoldings(false);
      handleEditHolding(false);
      setEditHoldingModal((prev) => !prev);
      handleSaveHoldings(false);
      localStorage.removeItem("builderData");
      localStorage.removeItem("percentage");
      handleGetCurrRateValue(false);
    } else if (addNewHolding?.error) {
      handleLoaderShow(false);
      handleIsAlert({
        open: true,
        message: "Network Error",
        error: true,
      });
    }
  };

  const handleModal = () => {
    handleShowModal((prev) => !prev);
    handleUpdatePortfolioName(false);
    handleExistingNameError(false);
    isModifyHedge && handleUpdateDropdown(false);
    localStorage.removeItem("percentage");
  };

  const handleEditModal = () => {
    setEditHoldingModal((prev) => !prev);
  };

  const allPortfolio =
    Array.isArray(getAllPortfolio) &&
    orderBy(getAllPortfolio, ["DATE_CREATED_UPDATED"], ["desc"])?.reduce(
      (acc, cv) => {
        acc.push({
          label: cv.NAME,
          id: cv.ID,
        });
        return acc;
      },
      []
    );

  return props ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="pt-80">
        <div className="">
          <div className="container">
            {title ? (
              <h1 className="mb-20 text-grey-600">
                <Tooltip info={titleInfo} size="lg">
                  <span>{title}</span>
                </Tooltip>
              </h1>
            ) : null}
          </div>
          {!isEmpty(tabList) ? (
            <>
              <div className="container">
                <div className="mb-32 mt-40 flex items-center gap-32">
                  {isAuthenticated ? (
                    Array.isArray(getAllPortfolio) &&
                    Array.isArray(allPortfolio) &&
                    !isEmpty(allPortfolio) ? (
                      <Dropdown
                        list={allPortfolio}
                        className="min-w-[226px] gap-x-8 bg-grey-300"
                      />
                    ) : Array.isArray(getAllPortfolio) &&
                      Array.isArray(allPortfolio) &&
                      isEmpty(allPortfolio) ? (
                      <Dropdown
                        list={tabList?.[0]?.dropdown}
                        className="min-w-[226px] gap-x-8 bg-grey-300"
                      />
                    ) : (
                      <div className="h-48 min-w-[220px] animate-pulse rounded bg-grey-400"></div>
                    )
                  ) : (
                    <Dropdown
                      list={tabList?.[0]?.dropdown}
                      className="min-w-[220px] gap-x-8 bg-grey-300"
                    />
                  )}
                  {
                    <OnboardingModal
                      onBoardingModal={tabList[0].onBoardingModal}
                    />
                  }
                </div>
                <TabTriggers
                  tabList={tabList}
                  active={active}
                  handleActive={handleActive}
                />
              </div>
              <div>
                {map(tabList, (content, i) => {
                  return title ? (
                    <div
                      key={`${content?.title
                        ?.toString()
                        ?.split(" ")
                        .join("-")}-${i}`}
                      className={clsx({
                        block: active === i,
                        hidden: active !== i,
                      })}
                    >
                      {content?.holdings ? (
                        <Holdings
                          {...content}
                          handleSelectPortfolio={handleSelectPortfolio}
                          handleHoldingModal={handleHoldingModal}
                          handleEditHoldingModal={handleEditHoldingModal}
                          openEditHoldingModal={openEditHoldingModal}
                          handleModal={handleModal}
                          handleEditModal={handleEditModal}
                          setEditHoldingModal={setEditHoldingModal}
                          isAuthenticated={isAuthenticated}
                          handleEditPortfolio={handleEditPortfolio}
                        />
                      ) : content?.performance ? (
                        <Performance {...content} />
                      ) : (
                        <Alerts {...content} />
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}

function TabTriggers({ tabList, active, setActive }) {
  const { isAuthenticated } = useAuth0();
  const {
    getHedgeData,
    isNewRow,
    handleActive,
    settingTabActive,
    handleSettingTabActive,
  } = useHedgingContext();

  const settingUrl = localStorage.getItem("settings");

  useEffect(() => {
    if (isNewRow) {
      handleActive(0);
    }
  }, [handleActive, isNewRow]);

  useEffect(() => {
    if (settingTabActive) {
      handleActive(2);
    }
  }, [handleActive, settingTabActive]);

  useEffect(() => {
    if (isNewRow) {
      handleActive(0);
    }
  }, [handleActive, isNewRow]);

  useEffect(() => {
    if (!!settingUrl) {
      handleActive(2);
    }
  }, [handleActive, settingUrl]);

  const handleChangeTab = (i) => {
    handleActive(i);
    handleSettingTabActive(false);
    localStorage.removeItem("settings");
  };

  // Helper function to determine if a tab should be disabled
  const isTabDisabled = (title, index) => {
    const isMyHoldings = title === "My Holdings";
    const noHedgeHolding = !getHedgeData?.hedgeholding;

    if (index === 0) return false;
    if (isNewRow && (index === 1 || index === 2)) return true;
    if (!isAuthenticated || (isAuthenticated && noHedgeHolding)) {
      return !isMyHoldings;
    }
    return false;
  };

  return (
    <ul className="flex gap-x-40 border-b border-grey-400">
      {tabList.map(({ title, info }, i) => {
        const isDisabled = isTabDisabled(title, i);
        const shouldApplyOpacity = isDisabled && i !== 0;

        return title ? (
          <li
            className="border-r border-white last:border-0"
            key={`${title.replace(/\s+/g, "-")}-${i}`}
          >
            {!isDisabled ? (
              <button
                className={clsx("border-b-2 pb-8", {
                  "border-secondary font-mazzardBold text-primary":
                    active === i,
                  "border-transparent font-mazzardSemiBold text-grey-600":
                    active !== i,
                  "cursor-not-allowed": isDisabled,
                  "opacity-50": shouldApplyOpacity,
                })}
                onClick={() => !isDisabled && handleChangeTab(i)}
                disabled={isDisabled}
              >
                {title}
              </button>
            ) : (
              <DisableState info={info}>
                <button
                  className={clsx("border-b-2 pb-8", {
                    "border-secondary font-mazzardBold text-primary":
                      active === i,
                    "border-transparent font-mazzardSemiBold text-grey-600":
                      active !== i,
                    "cursor-not-allowed": isDisabled,
                    "opacity-50": shouldApplyOpacity,
                  })}
                  onClick={() => !isDisabled && handleActive(i)}
                  disabled={isDisabled}
                >
                  {title}
                </button>
              </DisableState>
            )}
          </li>
        ) : null;
      })}
    </ul>
  );
}

function Holdings(props) {
  const {
    linkList,
    table,
    hedge,
    total,
    hedgeLabel,
    setHedge,
    openHoldingModal,
    handleHoldingModal,
    handleEditHoldingModal,
    handleModal,
    handleEditModal,
    setHoldingsTitle,
    isAuthenticated,
    openEditHoldingModal,
    setEditHoldingModal,
    handleEditPortfolio,
  } = props;

  const {
    tableData,
    handleEditHolding,
    getHedgeData,
    editHolding,
    handleSaveHoldings,
    saveHoldings,
    changeEditLabel,
    handleChangeEditLabel,
    handleHoldingShow,
    handleTableData,
    modifyHedge,
    isHoldings,
    disableButton,
    isNewRow,
    handleEditDataEnable,
    handleRecall,
    editTableData,
    handleEditTableData,
    handleBlankValues,
    handleTabClicked,
  } = useHedgingContext();

  const handleEditHoldings = async () => {
    handleEditHolding(true);
    handleSaveHoldings(true);
    handleChangeEditLabel(false);
    handleHoldingShow(true);
    handleTableData(getHedgeData?.holdings);
    handleRecall(true);
    handleBlankValues(false);
    handleTabClicked(false);
  };

  const handlePreviousdata = async () => {
    handleSaveHoldings(false);
    handleEditHolding(false);
    handleEditDataEnable(true);
    handleEditTableData([]);
    handleBlankValues(false);
  };

  const betaNeagative = negativeBetaError(getHedgeData, editTableData);

  const checkBlankTicker = editHolding
    ? editTableData?.find((cv) => !cv.VALUE && !cv.UNITS && !!cv.TICKER)?.TICKER
    : tableData?.find((cv) => !cv.value && !cv.weight && !!cv.ticker)?.ticker;

  const checkEditHolding = editHolding
    ? tableData?.length > 1 && isHoldings
    : tableData?.length > 1 && isHoldings && !checkBlankTicker;

  return (
    <div className="pt-40">
      <div className="container my-16">
        {hedgeLabel?.title ? <LineTitle text={hedgeLabel?.title} /> : null}
        <div className="mb-32 mt-16 flex items-center justify-between">
          <Tooltip info={hedgeLabel?.options?.info} size="lg">
            <span className="inline-flex items-center gap-x-4 rounded-[38px] bg-secondary p-8 px-12 font-mazzardSemiBold text-2xs text-white">
              <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white text-secondary">
                <div className="m-auto">1</div>
              </div>
              <span>
                {isAuthenticated && editHolding
                  ? "Update Holdings"
                  : hedgeLabel?.options?.pill}
              </span>
            </span>
          </Tooltip>
          <div>
            {!isEmpty(linkList) ? (
              <div className="flex items-center gap-x-24">
                {isAuthenticated &&
                !saveHoldings &&
                !!getHedgeData?.holdings &&
                !modifyHedge &&
                !disableButton ? (
                  <button
                    className="cta btn btn-primary inline-block"
                    onClick={handleEditHoldings}
                  >
                    Edit Holdings
                  </button>
                ) : (
                  <>
                    {isAuthenticated && !isNewRow && saveHoldings ? (
                      <button
                        onClick={handlePreviousdata}
                        className="text-blue-200 underline"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                    ) : null}
                    {checkEditHolding || (saveHoldings && betaNeagative > 0) ? (
                      <button
                        className={`cta btn inline-block ${
                          checkEditHolding ||
                          (saveHoldings && betaNeagative > 0)
                            ? "btn-primary border-none"
                            : "btn-primary cursor-not-allowed cursor-not-allowed text-white opacity-50"
                        }`}
                        onClick={
                          editHolding || modifyHedge
                            ? handleEditHoldingModal
                            : handleHoldingModal
                        }
                        disabled={
                          checkEditHolding ||
                          (saveHoldings && betaNeagative > 0)
                            ? false
                            : true
                        }
                      >
                        {changeEditLabel && !modifyHedge && !isHoldings
                          ? "Edit Holdings"
                          : "Save Holdings"}
                      </button>
                    ) : (
                      <DisableState
                        info="You must set a hedge before saving"
                        tooltipLeft={true}
                      >
                        <button
                          className={`cta btn inline-block ${
                            checkEditHolding ||
                            (saveHoldings && betaNeagative > 0)
                              ? "btn-primary border-none"
                              : "btn-primary cursor-not-allowed cursor-not-allowed text-white opacity-50"
                          }`}
                          onClick={
                            editHolding || modifyHedge
                              ? handleEditHoldingModal
                              : handleHoldingModal
                          }
                          disabled={
                            checkEditHolding ||
                            (saveHoldings && betaNeagative > 0)
                              ? false
                              : true
                          }
                        >
                          {changeEditLabel && !modifyHedge && !isHoldings
                            ? "Edit Holdings"
                            : "Save Holdings"}
                        </button>
                      </DisableState>
                    )}
                  </>
                )}
                <Modal
                  openHoldingModal={openHoldingModal}
                  handleModal={handleModal}
                  setHoldingsTitle={setHoldingsTitle}
                />
                <EditModal
                  openEditHoldingModal={openEditHoldingModal}
                  handleEditModal={handleEditModal}
                  setEditHoldingModal={setEditHoldingModal}
                  setHoldingsTitle={setHoldingsTitle}
                  handleHoldingModal={handleHoldingModal}
                  handleEditPortfolio={handleEditPortfolio}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Table
        table={table}
        hedge={hedge}
        total={total}
        isAuthenticated={isAuthenticated}
        betaNeagative={betaNeagative}
        checkBlankTicker={checkBlankTicker}
      />
      {setHedge && <SetHedge {...setHedge} />}
    </div>
  );
}

function Table(props) {
  const {
    table,
    hedge,
    total,
    isAuthenticated,
    betaNeagative,
    checkBlankTicker,
  } = props;
  const {
    betaValue,
    handleBetaValue,
    isSelectIndex,
    isSelectedBeta,
    handleIsTotalPortfolio,
    isHoldings,
    isDropChange,
    isSelectMultiple,
    isRequiredInvestment,
    handleIsDropBeta,
    handleIsTableData,
    handleTableData,
    tableData,
    getHedgeData,
    isNewRow,
    handleIsAlert,
    selectedTickerBeta,
    rangeData,
    editHolding,
    handleIsHoldings,
    holdingShow,
    handleHedgeTotal,
    handleCurrentHedgeValue,
    getHoldingLoader,
    isSelectPortfolio,
    getAllPortfolio,
    handleSetUnit,
    targetRef,
    editTableData,
    tickerCheck,
    handleTickerCheck,
    handleNegativeTickers,
    blankValues,
    handleBlankValues,
    handleGetTickerResponse,
    handleTabClicked,
    handleTableIndex,
    handleEditValue,
    handleIsDropChange,
  } = useHedgingContext();

  const router = useRouter();

  useEffect(() => {
    handleTableData(tableData);
  }, [handleTableData, tableData]);

  const [tdWidth, setTdWidth] = useState(null);
  const tableArray = table?.filter((f) => {
    "contentType" in f ? delete f.contentType : null;
    "name" in f ? delete f.name : null;
    return f;
  });

  const headings = Object.values(tableArray[0]);

  const renderHeadingRow = (heading, index) => {
    return (
      <Cell
        key={`heading-${index}`}
        tableData={tableData}
        content={heading}
        header={true}
      />
    );
  };

  // new code
  const intialData = useMemo(
    () => ({
      ticker: "",
      ticker_name: "",
      market_close_value: "",
      weight: "",
      estUnit: "",
      value: "",
    }),
    []
  );

  let [data, setData] = useState([intialData]);
  let [searchData, setSearchData] = useState([]);
  let [inputValue, setInputValue] = useState("");
  let [hideValue, setHideValue] = useState(false);
  let [currDropdownIndex, setCurrDropdownIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [rowCount, setRowCount] = useState(1);
  const [rowDataCount, setRowDataCount] = useState(2);
  const [loading, setLoading] = useState(false);
  const [runTicker, setRunTicker] = useState({
    process: false,
    data: null,
    index: null,
  });

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedMultiple, setSelectedMultiple] = useState("");
  const [requiredInvestment, setRequiredInvestment] = useState("");
  const [indexMultipler, setIndexMultipler] = useState("");
  const [hedgeValue, setHedgeValue] = useState("");
  const [curruntHedge, setCurruntHedge] = useState("");

  useEffect(() => {
    !isDropChange &&
      setSelectedIndex(!!isSelectIndex ? isSelectIndex : "S&P 500");
    !isDropChange &&
      setSelectedMultiple(!!isSelectMultiple ? isSelectMultiple : "-1x");
    !isDropChange && setRequiredInvestment(isRequiredInvestment);
  }, [
    isDropChange,
    isRequiredInvestment,
    isSelectIndex,
    isSelectMultiple,
    selectedIndex,
    selectedMultiple,
  ]);

  useEffect(() => {
    handleIsTableData(data);
  }, [data, handleIsTableData]);

  const dropdownRef = useRef(null);

  const handleChangeRow = async (index, ticker, name, price) => {
    // handleSetUnit(false);
    const value = ticker;

    setHideValue(true);
    setInputValue("");
    handleGetTickerResponse(false);
    handleBlankValues(false);

    const tickerData = tableData?.some(
      (val) => val.ticker === value.toUpperCase()
    );

    if (tickerData) {
      handleIsAlert({
        open: true,
        message: `${value} Ticker is Already Added`,
        error: true,
      });
    }

    if (tableData.length >= 21) {
      handleIsAlert({
        open: true,
        message: "You have hit the limit.",
        error: false,
      });
    }

    if (value && !tickerData && tableData.length < 21) {
      const updateTableData = (data) => {
        const currentIndex = index;
        const newTableData = [...tableData];

        if (currentIndex < tableData?.length - 1) {
          newTableData[currentIndex] = {
            ...newTableData[currentIndex],
            ...data,
          };
        } else {
          newTableData[currentIndex] = {
            ...newTableData[currentIndex],
            ...data,
          };
          newTableData.push({ ...intialData });
        }

        handleTableData(newTableData);
      };

      const handleAlert = (message) => {
        handleIsAlert({ open: true, message, error: true });
      };

      const processData = (data) => {
        if (!isEmpty(data)) {
          if (!!data.ticker_name) {
            updateTableData(data);
          } else {
            handleAlert(
              "No data regarding this ticker. Please search for another"
            );
          }
        } else {
          handleAlert("No ticker available");
        }
      };

      const obj = {
        ticker: ticker,
        ticker_name: name,
        market_close_value: price,
      };

      processData(obj);

      setTimeout(
        () => setRunTicker({ process: true, data: value, index: index }),
        3000
      );
    }
  };

  // update table ticker with beta values
  const requestIdRef = useRef(0); // Use ref to track request ID

  const fetchData = async (value, id) => {
    const response = await mutate(apiKeys["hedgeTickerListKey"], () =>
      apiGetWithoutTokenRequest(`api/getticker?ticker=${value}`)
    );
    // Only update the state if this request ID is the latest
    if (id === requestIdRef.current) {
      return response;
    }
    return null; // Ignore outdated requests
  };

  useEffect(() => {
    const updateTableData = (data) => {
      const currentIndex = runTicker?.index;
      const newTableData = [...tableData];

      if (currentIndex < tableData?.length - 1) {
        newTableData[currentIndex] = {
          ...newTableData[currentIndex],
          ...data,
        };
      } else {
        newTableData[currentIndex] = {
          ...newTableData[currentIndex],
          ...data,
          ...intialData,
        };
      }
      handleTableData(newTableData);
    };
    const fetchAndUpdate = async () => {
      if (runTicker?.process && !!runTicker?.data) {
        const id = Date.now(); // Unique ID for each request
        requestIdRef.current = id; // Set the current request ID using ref

        const data = await fetchData(runTicker?.data, id);
        if (data && !isEmpty(data) && !!data.ticker_name) {
          updateTableData(data);
        }
        handleGetTickerResponse(true);
        setRunTicker(false);
      }
    };
    fetchAndUpdate();
  }, [
    handleGetTickerResponse,
    handleTableData,
    intialData,
    runTicker,
    tableData,
  ]);

  const handleUnitValueOnBlur = (e, index, column) => {
    handleSetUnit(true);
    const input = e.target;
    let value = input.value.trim();

    // Clean the value to allow only numbers, dots, and commas
    const cleanedValue = value.replace(/[^0-9.,]/g, "");

    // Convert cleaned value to a number
    let number = parseFloat(cleanedValue.replace(/,/g, "")) || 0;

    // Format number to two decimal places with commas
    let formattedValue = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Update the input field value
    input.value = formattedValue;

    // Update table data
    const data = [...tableData];
    data[index][column] = formattedValue;

    if (column === "estUnit") {
      const marketCloseValue = parseFloat(data[index].market_close_value);
      if (marketCloseValue && number) {
        const calculatedValue = (marketCloseValue * number).toFixed(2);
        data[index].value = Number(calculatedValue).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        data[index].value = "";
        data[index].estUnit = "";
      }
    } else if (column === "value") {
      const marketCloseValue = parseFloat(data[index].market_close_value);
      if (marketCloseValue && number) {
        const calculatedEstUnit = (number / marketCloseValue).toFixed(2); // Retain two decimal places
        data[index].estUnit = Number(calculatedEstUnit).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
      } else {
        data[index].estUnit = "";
        data[index].value = "";
      }
    }

    // Update table data
    handleTableData(data);
  };

  const handleChangeUnitValue = (e, index, column) => {
    !!isHoldings && handleIsHoldings(false);
    handleGetTickerResponse(true);
    handleTableIndex(index);
    handleIsDropChange(false);

    // Get the input element and its current value
    const input = e.target;

    if (input.value.length < 1) {
      handleSetUnit(false);
    }

    const originalCursorPosition = input.selectionStart;
    const originalLength = input.value.length;

    // Clean and parse the input value
    const value = input.value.trim();
    // Allow numbers, commas, and a single dot for decimals
    const cleanedValue = value.replace(/[^0-9.,]/g, "");

    const newData = [...tableData];

    // Update the value in the current row
    newData[index][column] = cleanedValue;

    if (column === "estUnit") {
      const market_close_value = parseFloat(newData[index].market_close_value);

      const unit = cleanedValue
        ? parseFloat(cleanedValue.replace(/,/g, ""))
        : NaN;

      if (!isNaN(market_close_value) && !isNaN(unit) && unit >= 0) {
        const calculatedValue = (market_close_value * unit).toFixed(2);
        const calculatedPostValue = market_close_value * unit;
        newData[index].value =
          unit > 0 ? Number(calculatedValue).toLocaleString() : "";
        newData[index].valuePost = unit > 0 ? Number(calculatedPostValue) : "";
      } else {
        newData[index].value = "";
        newData[index].valuePost = "";
      }
      // Format estUnit with commas for display
      newData[index].estUnit = cleanedValue || "";
      newData[index].unitPost = unit || "";
    } else if (column === "value") {
      handleEditValue(true);
      const market_close_value = parseFloat(newData[index].market_close_value);
      const parsedValue = cleanedValue
        ? parseFloat(cleanedValue.replace(/,/g, ""))
        : NaN;

      if (
        !isNaN(market_close_value) &&
        !isNaN(parsedValue) &&
        parsedValue >= 0
      ) {
        const estUnit =
          market_close_value > 0
            ? (parsedValue / market_close_value).toFixed(2)
            : "";
        const estPostUnit =
          market_close_value > 0 ? parsedValue / market_close_value : "";

        newData[index].estUnit = estUnit
          ? Number(estUnit).toLocaleString()
          : "";
        newData[index].unitPost = estPostUnit ? estPostUnit : "";
        newData[index].valuePost = parsedValue;
      } else {
        newData[index].estUnit = "";
        newData[index].unitPost = "";
      }

      // Format value with commas for display
      newData[index].value = cleanedValue;
    }

    // Recalculate the weights for all rows based on updated values
    const totalSum = newData.reduce((acc, cv) => {
      const value = parseFloat(cv.value.replace(/,/g, "")) || 0;
      return acc + value;
    }, 0);

    newData.forEach((row) => {
      const value = parseFloat(row.value.replace(/,/g, "")) || 0;
      row.weight =
        totalSum > 0 && value > 0 ? ((value / totalSum) * 100).toFixed(2) : "";
    });

    // Update the state with the new data
    handleTableData(newData);

    // Adjust cursor position after state update
    setTimeout(() => {
      const newLength = input.value.length;
      const diff = newLength - originalLength;
      const newCursorPosition = originalCursorPosition + diff;

      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const totalPortfolio = tableData?.reduce((acc, cv) => {
    // Remove commas and convert to float
    const value = parseFloat(cv.value?.replace(/,/g, "") || 0);
    return acc + value;
  }, 0);

  const uniqueTickers = Array.from(
    new Set(getHedgeData?.holdings?.map((item) => item.TICKER))
  ).map((ticker) =>
    getHedgeData?.holdings.find((item) => item.TICKER === ticker)
  );

  const totalPortfolio2 = updatedPortfolioData(getHedgeData?.holdings)?.reduce(
    (acc, cv) => {
      return acc + parseFloat(cv.VALUE || 0);
    },
    0
  );

  function useDebounce(callback, delay) {
    const [timeoutId, setTimeoutId] = useState(null);

    function debounceFunction(...args) {
      clearTimeout(timeoutId);
      const id = setTimeout(() => {
        callback(...args);
      }, delay);
      setTimeoutId(id);
    }

    return debounceFunction;
  }

  // Custom hook for debouncing the input change handler
  const handleInputChangeDebounced = useDebounce(async (value) => {
    const tickerList = await mutate(
      apiKeys["hedgeTickerListKey"],
      () =>
        apiGetWithoutTokenRequest(
          `api/searchticker?ticker=${value}&pageno=1&rows=20`,
          true
        ),
      { revalidate: true }
    );

    if (!isEmpty(tickerList) && Array.isArray(tickerList)) {
      setSearchData(tickerList);
    } else {
      // alert("ticker not found");
      handleIsAlert({
        open: true,
        message: "Ticker not found",
        error: true,
      });
    }
  }, 1000);

  const handleSearchRow = (index, column, e) => {
    const value = e.target.value;
    setInputValue(value);
    setHideValue(false);
    setCurrDropdownIndex(index);
    handleInputChangeDebounced(value);
    setIsOpen(true);
    handleTabClicked(false);
    handleTableIndex(index);
    handleEditValue(false);
  };

  const dataCheck = editHolding
    ? tickerCheck && filterDataEdit(editTableData, isSelectedBeta)
    : tickerCheck && filterData(tableData, isSelectedBeta);

  useEffect(() => {
    if (dataCheck?.length === 0) {
      handleTickerCheck(false);
    }
  }, [dataCheck, handleTickerCheck]);

  // for delete any row
  const handleDeleteRow = (ticker, index, ticker_name) => {
    if (dataCheck > 0) {
      dataCheck?.filter((str) => str !== ticker_name);
    }

    const newData = [...tableData];
    !isEmpty(ticker) ? newData.splice(index, 1) : newData;

    const totalPortfolio = newData?.reduce((acc, cv) => {
      let value = parseFloat(cv.value?.replace(/,/g, "") || 0);
      return acc + value;
    }, 0);

    const updateWeightOnDelete = newData.map((item) => {
      let itemVal = parseFloat(item.value.replace(/,/g, ""));

      if (item.hasOwnProperty("weight")) {
        return {
          ...item,
          weight:
            itemVal > 0 ? ((itemVal / totalPortfolio) * 100).toFixed(2) : "",
        };
      }
      return item;
    });

    handleTableData(updateWeightOnDelete);
    handleNegativeTickers([]);
    handleBlankValues(false);
  };

  // This beta value is for New flow
  const totalBetaValue = tableData?.reduce((acc, curr) => {
    // Check if the current object (curr) has the key that matches isSelectedBeta
    // let itemVal = isAuthenticated ? curr.value : parseFloat(curr.value.replace(/,/g, ""));
    let itemVal =
      !isAuthenticated ||
      (isAuthenticated && isNewRow) ||
      (isAuthenticated && isEmpty(getAllPortfolio))
        ? parseFloat(curr.value.replace(/,/g, ""))
        : curr.value;

    if (curr.hasOwnProperty(`beta_${isSelectedBeta?.toLowerCase()}`)) {
      const value = parseFloat(itemVal || 0);
      const selectedBeta = parseFloat(
        curr[`beta_${isSelectedBeta?.toLowerCase()}`] || 0
      );
      return acc + value * selectedBeta;
    } else {
      // Handle the case where isSelectedBeta key is not found in the current object
      const value = parseFloat(itemVal || 0);
      return acc + value * parseFloat(curr.beta_gspc_indx || 0);
    }
  }, 0);

  // const totalBetaValueEEm = data?.reduce((acc, curr) => {
  //   // Check if the current object (curr) has the key that matches isSelectedBeta
  //   if (curr.hasOwnProperty(`beta_dja_indx`)) {
  //     const value = parseFloat(curr.value || 0);
  //     const selectedBeta = parseFloat(curr[`beta_dja_indx`] || 0);
  //     return acc + value * selectedBeta;
  //   } else {
  //     // Handle the case where isSelectedBeta key is not found in the current object
  //     const value = parseFloat(curr.value || 0);
  //     return acc + value * parseFloat(curr.beta_gspc_indx || 0);
  //   }
  // }, 0);

  const betaValues = useMemo(() => {
    const obj = {
      afty: "afty",
      dja_indx: "dja_indx",
      eem: "eem",
      efa: "efa",
      gspc_indx: "gspc_indx",
      iwm: "iwm",
      mid_indx: "mid_indx",
      ndx_indx: "ndx_indx",
    };

    const values = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const totalBetaValue = tableData?.reduce((acc, curr) => {
          let itemVal =
            !isAuthenticated ||
            (isAuthenticated && isNewRow) ||
            (isAuthenticated && isEmpty(getAllPortfolio))
              ? parseFloat(curr.value.replace(/,/g, ""))
              : curr.value;

          if (curr.hasOwnProperty(`beta_${key}`)) {
            const value = parseFloat(itemVal || 0);
            const selectedBeta = parseFloat(curr[`beta_${key}`] || 0);
            return acc + value * selectedBeta;
          } else {
            const value = parseFloat(itemVal || 0);
            return acc + value * parseFloat(curr[`beta_${key}`] || 0);
          }
        }, 0);

        values.push({ name: key, totalBetaValue: totalBetaValue });
      }
    }
    return values;
  }, [getAllPortfolio, isAuthenticated, isNewRow, tableData]);

  const totalSum = tableData?.reduce((acc, cv) => {
    return acc + parseFloat(cv.value || 0);
  }, 0);

  const uniqueTickers2 = Array.from(
    new Set(editTableData?.map((item) => item.TICKER))
  )
    .map((TICKER) => {
      const item = editTableData.find((data) => data.TICKER === TICKER);

      if (item) {
        return {
          ...item,
          VALUE: item.VALUE
            ? parseFloat(item.VALUE.toString().replace(/,/g, ""))
            : null,
        };
      }

      return null;
    })
    ?.filter(Boolean); // Filter out any null values

  // Calculate the total of all values
  const editTableTotal = uniqueTickers2.reduce((sum, item) => {
    return sum + (item.VALUE ? item.VALUE : 0);
  }, 0);

  useEffect(() => {
    handleBetaValue(totalBetaValue);
    handleIsDropBeta(betaValues);
    handleIsTotalPortfolio(totalPortfolio);
  }, [
    betaValues,
    handleBetaValue,
    handleIsDropBeta,
    handleIsTotalPortfolio,
    totalBetaValue,
    totalPortfolio,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleUpdateRow = async () => {
    setRowCount(2);
    setRowDataCount(rowDataCount + 1);

    const updateTickerList = await mutate(
      apiKeys["hedgeTickerListKey"],
      () =>
        apiGetWithoutTokenRequest(
          `api/searchticker?ticker=2&pageno=${rowDataCount}&rows=20`
        ),
      { revalidate: true }
    );

    if (!isEmpty(updateTickerList) && Array.isArray(updateTickerList)) {
      setSearchData([...searchData, ...updateTickerList]);
    }
  };

  const totalHedgeExpo = betaValue * (rangeData / 100);

  useEffect(() => {
    if (holdingShow) {
      const parsedBeta = Math.abs(Number(selectedTickerBeta));
      const hedgeValue = totalHedgeExpo / parsedBeta;
      const hedgeTotal = (hedgeValue + totalPortfolio).toFixed(2);

      setIndexMultipler(`${selectedMultiple} ${selectedIndex}`);
      setHedgeValue(hedgeValue);
      setHedgeValue(editHolding ? requiredInvestment : hedgeValue);
      handleHedgeTotal(hedgeTotal);
    }
  }, [
    holdingShow,
    editHolding,
    requiredInvestment,
    selectedIndex,
    selectedMultiple,
    selectedTickerBeta,
    totalHedgeExpo,
    totalPortfolio,
    handleHedgeTotal,
  ]);

  const currentHedgeValue =
    getHedgeData?.hedgeholding?.HEDGED_UNITS *
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE);

  useEffect(() => {
    handleCurrentHedgeValue(currentHedgeValue);
  }, [currentHedgeValue, handleCurrentHedgeValue]);

  // This is for total holdings change on set Hedge Click
  useEffect(() => {
    !isDropChange &&
      setCurruntHedge(
        isHoldings
          ? isRequiredInvestment + totalPortfolio
          : totalPortfolio.toFixed(2)
      );
  }, [isDropChange, isHoldings, isRequiredInvestment, totalPortfolio]);

  const getPortfolioId = localStorage.getItem("portfolio_Id");

  return (
    <div className="container relative">
      {(!getHoldingLoader &&
        getHedgeData?.holdings?.[0]?.PORTFOLIO_ID === isSelectPortfolio) ||
      getHedgeData?.[0]?.PORTFOLIO_ID === isSelectPortfolio ||
      getHedgeData?.holdings?.[0]?.PORTFOLIO_ID ===
        Number(router?.query?.portfolio_id) ||
      !!getPortfolioId ? (
        <>
          <div ref={targetRef} className="no-scrollbar overflow-x-scroll">
            <div className="mb-32 min-h-[218px]">
              <table className="w-full table-auto border-separate border-spacing-x-[6px] border-spacing-y-[6px]">
                <thead className={clsx("p-8 text-xs text-white")}>
                  <tr key="heading">
                    {map(headings, renderHeadingRow)}
                    {!isAuthenticated && !isEmpty(tableData[0].ticker) ? (
                      <Cell empty={true} header={true} />
                    ) : null}
                  </tr>
                </thead>
                <TableBody
                  data={tableData}
                  handleTableData={handleTableData}
                  inputValue={inputValue}
                  searchData={searchData}
                  handleSearchRow={handleSearchRow}
                  currDropdownIndex={currDropdownIndex}
                  hideValue={hideValue}
                  handleChangeRow={handleChangeRow}
                  handleDeleteRow={handleDeleteRow}
                  handleChangeUnitValue={handleChangeUnitValue}
                  handleUnitValueOnBlur={handleUnitValueOnBlur}
                  setIsOpen={setIsOpen}
                  dropdownRef={dropdownRef}
                  handleUpdateRow={handleUpdateRow}
                  isOpen={isOpen}
                  loading={loading}
                  setTdWidth={setTdWidth}
                  isAuthenticated={isAuthenticated}
                  getData={getHedgeData}
                  dataCheck={dataCheck}
                />
              </table>

              {dataCheck.length > 0 && (
                <div>
                  <div className="py-16 pl-8 text-red">
                    This ticker is not valid{" "}
                    {dataCheck?.map((str, i) => {
                      return (
                        <span key={i}>
                          {str}
                          {i < dataCheck.length - 1 && ",  "}
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
              {(betaValue < 0 && editHolding) ||
              (betaNeagative < 0 && editHolding) ? (
                <div className="pl-8 pt-16 text-red">
                  Unable to save changes as the Hedge requirements are negative
                </div>
              ) : (
                ""
              )}

              {!!checkBlankTicker && blankValues && (
                <div className="pl-8 pt-16 text-red">
                  {`Please complete the holding units or value for (${checkBlankTicker}) or remove from the list.`}
                </div>
              )}

              {(get(total, "withoutHedge") && isHoldings) ||
              (isAuthenticated && !isNewRow) ? (
                <div
                  className={`total-holdings-without-hedge mt-32 flex items-center justify-between border-b
     border-grey-400 ${isAuthenticated ? "pr-0" : "pr-[54px]"}
     `}
                >
                  <div className="flex gap-x-4 py-4 pl-12 pr-8">
                    <Tooltip color="black" info={hedge?.info}>
                      <span className="font-mazzardBold text-2xs">
                        {total?.withoutHedge}
                      </span>
                    </Tooltip>
                  </div>
                  <div
                    className={`font-mazzardBold ${
                      isAuthenticated && isNewRow
                        ? "pr-[50px]"
                        : isAuthenticated
                        ? "pr-12"
                        : (isAuthenticated && editHolding) ||
                          (isAuthenticated && !isNewRow)
                        ? "pr-56"
                        : ""
                    }`}
                  >
                    {isAuthenticated && !isEmpty(getHedgeData) && !isNewRow
                      ? !!getHedgeData?.hedgeholding
                        ? `$${formatDecimalNumber(
                            editHolding ? editTableTotal : totalPortfolio2
                          )}`
                        : null
                      : totalPortfolio > 0
                      ? `$${formatDecimalNumber(totalPortfolio)}`
                      : "--"}
                  </div>
                </div>
              ) : null}

              {get(hedge, "label") ? (
                <div className="hedge-total relative">
                  {/* <div
                    className={`z-1 pb-5 duration-800 absolute h-[30px] w-[1298px] transition${
                      scrollView
                        ? "duration-800 border-2 border-primary pr-[54px] transition"
                        : "duration-800 border-none pr-[54px] transition"
                    }`}
                  ></div> */}
                  <div
                    className={`z-0 mt-20 flex items-center ${
                      (isAuthenticated ? "pr-0" : "pr-[54px]",
                      `border-b border-grey-400 ${
                        isAuthenticated ? "pr-0" : "pr-[54px]"
                      }`)
                    }`}
                    // className={`z-0 mt-20 flex items-center ${
                    //   (isAuthenticated ? "pr-0" : "pr-[54px]",
                    //   scrollView
                    //     ? `border-none ${
                    //         isAuthenticated ? "pr-0" : "pr-[54px]"
                    //       }`
                    //     : `border-b border-grey-400 ${
                    //         isAuthenticated ? "pr-0" : "pr-[54px]"
                    //       }`)
                    // }`}
                  >
                    <div
                      style={{
                        width: `${isAuthenticated ? "200px" : tdWidth}px`,
                      }}
                      className={`shrink-0 py-4 pl-12 pr-4 ${
                        isAuthenticated && "w-[190px]"
                      }`}
                    >
                      <Tooltip color="black" info={hedge?.info}>
                        <span className="font-mazzardBold text-2xs">
                          {hedge?.label}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="flex w-full items-center justify-between pl-4">
                      <div className="currunt-index">
                        {/* {updateModifyHedge || updateDropdown
                          ? `${getHedgeData?.hedgeholding?.MULTIPLIER}x ${getHedgeData?.hedgeholding?.INDEX_NAME} ETF`
                          : ""} */}
                        {isAuthenticated &&
                        !isEmpty(getHedgeData?.hedgeholding) &&
                        !isNewRow &&
                        !isHoldings
                          ? `${
                              !!getHedgeData?.hedgeholding
                                ? `${getHedgeData?.hedgeholding?.MULTIPLIER}x`
                                : ""
                            } ${
                              !!getHedgeData?.hedgeholding
                                ? `${
                                    getHedgeData?.hedgeholding?.INDEX_NAME.includes(
                                      "Nasdaq"
                                    )
                                      ? getHedgeData?.hedgeholding?.INDEX_NAME?.toUpperCase()
                                      : getHedgeData?.hedgeholding?.INDEX_NAME
                                  } ETF`
                                : ""
                            }`
                          : isHoldings
                          ? `${indexMultipler} ETF`
                          : null}
                      </div>
                      <div
                        className={`hedge-holdings font-mazzardBold ${
                          isAuthenticated && isNewRow
                            ? "pr-[50px]"
                            : isAuthenticated
                            ? "pr-12"
                            : (isAuthenticated && editHolding) ||
                              (isAuthenticated && !isNewRow)
                            ? "pr-56"
                            : ""
                        }`}
                      >
                        {requiredInvestment && isHoldings
                          ? `$${formatDecimalNumber(requiredInvestment)}`
                          : getHedgeData?.hedgeholding?.REQUIRED_INVESTMENT ==
                              undefined || isNewRow
                          ? ""
                          : `$${formatDecimalNumber(currentHedgeValue)}`}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {get(total, "label") ? (
                <div
                  className={`total-holdings mt-16 flex items-center justify-between border-b
     border-grey-400 ${isAuthenticated ? "pr-0" : "pr-[54px]"}
     `}
                >
                  <div className="flex gap-x-4 py-4 pl-12 pr-8">
                    <span className="pr-32 font-mazzardBold text-2xs">
                      {total?.label}
                    </span>
                  </div>
                  <div
                    className={`total-holdings font-mazzardBold ${
                      isAuthenticated && isNewRow
                        ? "pr-[50px]"
                        : isAuthenticated
                        ? "pr-12"
                        : (isAuthenticated && editHolding) ||
                          (isAuthenticated && !isNewRow)
                        ? "pr-56"
                        : ""
                    }`}
                  >
                    {isAuthenticated && !isEmpty(getHedgeData) && !isNewRow
                      ? !!getHedgeData?.hedgeholding
                        ? `$${formatDecimalNumber(
                            editHolding
                              ? currentHedgeValue + editTableTotal
                              : currentHedgeValue + totalPortfolio2
                          )}`
                        : null
                      : totalPortfolio > 0
                      ? `$${parseFloat(curruntHedge).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      : "--"}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="absolute right-0 top-0 block h-full w-40 bg-white/50 lg:hidden"></div>
        </>
      ) : (
        <HoldingLoader />
      )}
    </div>
  );
}

function TableBody(props) {
  const {
    data,
    inputValue,
    searchData,
    handleSearchRow,
    currDropdownIndex,
    hideValue,
    handleChangeRow,
    handleChangeUnitValue,
    handleUnitValueOnBlur,
    handleDeleteRow,
    isOpen,
    dropdownRef,
    handleUpdateRow,
    loading,
    setTdWidth,
    isAuthenticated,
    dataCheck,
  } = props;

  const {
    isNewRow,
    getHedgeData,
    editHolding,
    setUnit,
    tableData,
    handleTabClicked,
    tabClicked,
    tableIndex,
    editValue,
  } = useHedgingContext();

  const updatedData = updatedPortfolioData(getHedgeData?.holdings);

  const portfolioData = updatedData?.map((item) => {
    return {
      ...item,
      UNITS: parseFloat(item.UNITS).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      VALUE: parseFloat(item.VALUE).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    };
  });

  const tdRef = useRef(null);
  const inputRefs = useRef(tableData.map(() => React.createRef()));

  // Ensure inputRefs are initialized correctly based on tableData length
  useEffect(() => {
    inputRefs.current = tableData.map(
      (_, index) => inputRefs.current[index] || React.createRef()
    );
  }, [tableData]);

  useEffect(() => {
    const tdElement = tdRef.current;
    // Check if the element is available
    if (tdElement) {
      // Get the width of the td element
      const tdWidth = tdElement.offsetWidth + 10;
      setTdWidth(tdWidth);
    }
  }, [setTdWidth]);

  // const = [tabSetEnter, tabSetEnter] = useState(true)

  // Effect to focus the input when tabClicked is true
  const check = tableData
    ?.filter((val) => val.ticker !== "")
    ?.some((val) => val.estUnit === "");

  useEffect(() => {
    if (check && !editValue) {
      inputRefs?.current[tableIndex]?.current.focus();
      inputRefs?.current[tableIndex]?.current.select();
    }
  }, [check, editValue, tableIndex]);

  return (
    <tbody className="builder-tabs__table">
      {isAuthenticated && !Array.isArray(portfolioData) ? (
        <TableLoader />
      ) : isAuthenticated &&
        Array.isArray(portfolioData) &&
        !isNewRow &&
        portfolioData?.length &&
        !editHolding ? (
        portfolioData?.map((val, index) => {
          return (
            <tr key={index} className="mt-4">
              <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100 pl-8">
                {val.TICKER}
              </td>
              <td className="w-[26%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 pl-8">
                {val.TICKER_NAME}
              </td>
              <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 pl-8 text-right">
                {`$${formatDecimalNumber(val.CURRENT_PRICE)}`}
              </td>
              <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 pl-8 text-right">
                {`${val.WEIGHT}%`}
              </td>
              <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100 p-8 text-right">
                {val.UNITS}
              </td>
              <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100 p-8 text-right">
                {`$${val.VALUE}`}
              </td>
            </tr>
          );
        })
      ) : editHolding ? (
        <EditPortfolio />
      ) : (
        <>
          {data?.map((row, index) => {
            return (loading && index !== data.length - 1) || !loading ? (
              <tr key={index} className="mt-4">
                {row.ticker ? (
                  <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100 pl-8">
                    {row.ticker}
                  </td>
                ) : (
                  <td
                    ref={tdRef}
                    className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100"
                  >
                    <div className="relative">
                      <div className="builder-tabs__search">
                        <input
                          type="text"
                          placeholder="Enter Ticker"
                          disabled={
                            data.length > 1 && !data[data.length - 2]?.estUnit
                          }
                          data-id={index}
                          data-ticker={row.ticker ?? ""}
                          value={
                            inputValue.length > 0 && currDropdownIndex === index
                              ? inputValue
                              : row.ticker
                          }
                          className="size-full bg-transparent p-4 placeholder:italic focus:outline-none"
                          onChange={(e) => {
                            handleSearchRow(index, "ticker", e);
                          }}
                          onKeyDown={(e) => {
                            // Check if Enter key is pressed
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent any default behavior like form submission
                              handleChangeRow(
                                tableData.length - 1,
                                searchData?.[0]?.TICKER,
                                searchData?.[0]?.TICKER_NAME,
                                searchData?.[0]?.MARKET_CLOSE_VALUE
                              );
                            }
                          }}
                          aria-label="Search"
                        />
                      </div>
                      {searchData?.length != 0 &&
                        !hideValue &&
                        currDropdownIndex === index &&
                        isOpen && (
                          <ul
                            role="listbox"
                            ref={dropdownRef}
                            className="top-106% absolute left-0 right-0 z-50 h-[160px] w-[200%] overflow-auto border border-b-0 border-grey-500 bg-grey-200"
                          >
                            {searchData?.map((value, key) => {
                              return (
                                <li
                                  key={key}
                                  role="listitem"
                                  className={`${
                                    !tabClicked
                                      ? "border-black first:border"
                                      : null
                                  }`}
                                  onFocus={() => {
                                    handleTabClicked(true);
                                  }}
                                >
                                  <button
                                    className="text-16 w-full cursor-pointer border-b border-grey-500 p-4 text-left"
                                    onClick={(e) => {
                                      handleChangeRow(
                                        index,
                                        value.TICKER,
                                        value.TICKER_NAME,
                                        value.MARKET_CLOSE_VALUE
                                      );
                                    }}
                                  >
                                    <span className="font-mazzardSemiBold text-secondary">
                                      {value.TICKER}
                                    </span>
                                    - {value.TICKER_NAME}
                                  </button>
                                </li>
                              );
                            })}

                            {searchData?.length > 19 ? (
                              <li role="listitem">
                                <button
                                  onClick={() => handleUpdateRow()}
                                  className="my-8 ml-8 inline-block cursor-pointer rounded-xl border border-b border-grey-500 bg-grey-100 px-12 py-4 text-[14px] font-semibold text-secondary"
                                  aria-label="list"
                                >
                                  View More
                                </button>
                              </li>
                            ) : null}
                          </ul>
                        )}
                    </div>
                  </td>
                )}
                <td
                  className={clsx(
                    "w-[35%] border border-l-0 border-r-0 border-t-0 p-4",
                    dataCheck && dataCheck?.includes(row.ticker_name)
                      ? "border-b-red"
                      : "border-b-grey-500"
                  )}
                >
                  {`${row.ticker_name}`}
                </td>
                <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 text-right">
                  {row.market_close_value?.toString().length < 1
                    ? `${row.market_close_value}`
                    : `$${Number(row.market_close_value).toFixed(2)}`}
                </td>
                <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 text-right">
                  {row?.weight?.toString()?.length > 0 ? `${row.weight}%` : ""}
                </td>
                <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100">
                  <input
                    ref={inputRefs.current[index]}
                    className="size-full bg-transparent p-4 text-right focus:outline-none"
                    disabled={!row.ticker ? true : false}
                    value={!!row.estUnit ? row.estUnit : ""}
                    // value={
                    //   !!row.estUnit ? `$${intlFormat(row.estUnit).showVal}` : ""
                    // }
                    type="text"
                    onChange={(e) => {
                      if (setUnit) {
                        handleChangeUnitValue(e, index, "estUnit");
                      } else {
                        if (e.target.value.length <= 8) {
                          handleChangeUnitValue(e, index, "estUnit");
                        }
                      }
                    }}
                    aria-label="Units"
                    onBlur={(e) => handleUnitValueOnBlur(e, index, "estUnit")}
                  />
                </td>
                <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100">
                  <input
                    type="text"
                    className="size-full appearance-none bg-transparent p-4 text-right focus:outline-none"
                    disabled={!row.ticker ? true : false}
                    value={!!row.value ? `$${row.value}` : ""}
                    // value={
                    //   !!row.value ? `$${intlFormat(row.value).showVal}` : ""
                    // }
                    onChange={(e) => handleChangeUnitValue(e, index, "value")}
                    onBlur={(e) => handleUnitValueOnBlur(e, index, "value")}
                    aria-label="Value"
                  />
                </td>
                {!isEmpty(row.ticker) ? (
                  <td className="w-[4%] text-center">
                    <button
                      onClick={() =>
                        handleDeleteRow(row.ticker, index, row.ticker_name)
                      }
                      aria-label="Tuple remove"
                    >
                      <Icon className="text-14" name="tuple-remove-purple" />
                    </button>
                  </td>
                ) : null}
              </tr>
            ) : null;
          })}
        </>
      )}
    </tbody>
  );
}

const handleSort = (
  content,
  data,
  handleTableData,
  sortDir,
  setSortDir,
  handleEditSortData,
  handleEditDataEnable,
  handleEditDeleteValue
) => {
  // Automatically determine if it's the editable table based on key presence
  const isEditTable = data.some((item) => Object.keys(item).includes("WEIGHT"));

  // Toggle the sorting direction
  const direction = sortDir ? "asc" : "desc";

  // Determine key names based on the table type
  const weightKey = isEditTable ? "WEIGHT" : "weight";
  const estUnitKey = isEditTable ? "UNITS" : "estUnit";
  const valueKey = isEditTable ? "VALUE" : "value";

  const parseValues = (item) => ({
    ...item,
    [weightKey]:
      item[weightKey] === "0.00"
        ? "0.00"
        : item[weightKey]
        ? parseFloat(item[weightKey]?.replace(/,/g, ""))
        : "",
    [estUnitKey]: item[estUnitKey]
      ? parseFloat(item[estUnitKey].replace(/,/g, ""))
      : "",
    [valueKey]: item[valueKey]
      ? parseFloat(item[valueKey].replace(/,/g, ""))
      : "",
  });

  const formatValues = (item) => ({
    ...item,
    [weightKey]: item[weightKey]
      ? item[weightKey].toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "",
    [estUnitKey]: item[estUnitKey]
      ? item[estUnitKey].toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "",
    [valueKey]: item[valueKey]
      ? item[valueKey].toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : "",
  });

  const convertDataToString = (data) => data.map(formatValues);

  let newData = data.map(parseValues);

  // Handle sorting by content label
  const sortMap = {
    Ticker: isEditTable ? "TICKER" : "ticker",
    "Holding Name": isEditTable ? "TICKER_NAME" : "ticker_name",
    "Price ($)": isEditTable ? "PRICE" : "market_close_value",
    "Weight (%)": weightKey,
    "Est. Units": estUnitKey,
    "Value ($)": valueKey,
  };

  const sortKey = sortMap[content.label];

  if (sortKey) {
    const lastObject = newData.pop();
    let sortedData = orderBy(newData, [sortKey], [direction]);
    sortedData.push(lastObject); // Re-add the last object to the end of the sorted array
    newData = sortedData;
    handleTableData(convertDataToString(newData));
    handleEditSortData(convertDataToString(newData));
    handleEditDataEnable(false);
    handleEditDeleteValue(true);
  }
  setSortDir((prev) => !prev);
};

const handleSortAfterLogin = (
  content,
  data,
  handleTableData,
  sortDir,
  setSortDir,
  handleIsLoginTableData,
  getHedgeData
) => {
  // Toggle the sorting direction
  const direction = sortDir ? "asc" : "desc";

  // Determine key names based on the table type
  const weightKey = "WEIGHT";
  const estUnitKey = "UNITS";
  const valueKey = "VALUE";

  // Handle sorting by content label
  const sortMap = {
    Ticker: "TICKER",
    "Holding Name": "TICKER_NAME",
    "Price ($)": "PRICE",
    "Weight (%)": weightKey,
    "Est. Units": estUnitKey,
    "Value ($)": valueKey,
  };

  const sortKey = sortMap[content.label];

  if (sortKey) {
    let sortedData = orderBy(
      data,
      (item) =>
        sortKey === weightKey ? parseFloat(item[weightKey]) : item[sortKey],
      [direction]
    );
    // handleTableData(sortedData);  // RERENDERING TABLE FIX AFTER COMMENTING THIS
    handleIsLoginTableData({ ...getHedgeData, holdings: sortedData });
  }
  setSortDir((prev) => !prev);
};

const Cell = forwardRef((props, ref) => {
  const [sortDir, setSortDir] = useState(true);
  const { header, content, row, empty, length, bodyRef, rowData } = props || {};
  const {
    tableData,
    handleTableData,
    editTableData,
    handleEditTableData,
    isNewRow,
    editHolding,
    getHedgeData,
    handleGetHedgeData,
    handleIsLoginTableData,
    handleEditSortData,
    handleEditDataEnable,
    handleEditDeleteValue,
  } = useHedgingContext();

  const { isAuthenticated } = useAuth0();

  const passTableData = editHolding
    ? editTableData
    : !isAuthenticated || (isAuthenticated && isNewRow)
    ? tableData
    : getHedgeData?.holdings;

  const passTableDataHandler = editHolding
    ? handleEditTableData
    : !isAuthenticated || (isAuthenticated && isNewRow)
    ? handleTableData
    : handleGetHedgeData;

  const { info, label, alignRight, tooltipLeft } = content || {};
  const data = typeof content === "object" ? label : content;
  const bg = length == 0 || length == 4 || length == 5;

  const removeTuple = (e) => {
    if (typeof window !== undefined) {
      if (document.querySelectorAll(".builder-tabs__table tr").length > 1) {
        e.currentTarget.parentElement.parentElement.parentElement.remove();
      }
    }
  };

  return (
    <td
      className={clsx(
        "text-dark",
        { "border-b-4 border-primary pl-0 text-sm": header },
        { "border-b border-grey-400 pl-0 pt-8 text-2xs": !header && !empty }
      )}
    >
      {!empty ? (
        <span
          className={clsx(
            "flex p-8",
            { "justify-end": !label },
            { "gap-x-8": header },
            { "justify-end pr-0": alignRight || typeof data === "number" },
            { "border-b border-dark bg-grey-200": bg }
          )}
        >
          <Tooltip info={info} tooltipLeft={tooltipLeft}>
            <span className="font-mazzardSemiBold ">{data}</span>
          </Tooltip>
          {header ? (
            <button
              onClick={() =>
                !editHolding &&
                !(!isAuthenticated || (isAuthenticated && isNewRow))
                  ? handleSortAfterLogin(
                      content,
                      passTableData,
                      passTableDataHandler,
                      sortDir,
                      setSortDir,
                      handleIsLoginTableData,
                      getHedgeData
                    )
                  : handleSort(
                      content,
                      passTableData,
                      passTableDataHandler,
                      sortDir,
                      setSortDir,
                      handleEditSortData,
                      handleEditDataEnable,
                      handleEditDeleteValue
                    )
              }
              aria-label="Sorting caret"
            >
              <Icon name={sortDir ? "sorting-caret" : "sorting-caret-up"} />
            </button>
          ) : null}
        </span>
      ) : (
        <span className="flex items-center justify-center">
          {!header ? (
            <button
              className=""
              onClick={(e) => removeTuple(e)}
              aria-label="Tuple remove"
            >
              <Icon name="tuple-remove-purple" />
            </button>
          ) : null}
        </span>
      )}
    </td>
  );
});
Cell.displayName = "Cell";

function Performance(props) {
  const { performanceRange, performanceGraph } = props;

  return (
    <div className="">
      {performanceRange && <PerformanceRange {...performanceRange} />}
      {performanceRange && <PerformanceGraph {...performanceGraph} />}
    </div>
  );
}

function Alerts(props) {
  const { notifications } = props;

  return (
    <div className="">
      {!isEmpty(notifications) ? <Notification {...notifications} /> : null}
    </div>
  );
}

function Modal(props) {
  const { openHoldingModal, handleModal, setHoldingsTitle } = props;

  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const [portfolioId, setPortfolioId] = useState(0);
  const [builderData, setBuilderData] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // tab press
  const [isTabPressed, setIsTabPressed] = useState(false);

  const router = useRouter();

  const {
    tableData,
    getHedgeData,
    rangeData,
    betaValue,
    selectedIndexId,
    isSelectPortfolio,
    handleAddNewRow,
    handleIsAlert,
    isHoldings,
    handleIsHoldings,
    handleAllPortfolio,
    handleSelectPortfolio,
    editHolding,
    selectedTickerBeta,
    handleEditHolding,
    loaderShow,
    handleLoaderShow,
    handleSaveHoldings,
    getTickerBeta,
    getAllPortfolio,
    handleInvestmentLoader,
    handleGetHoldingLoader,
    editTableData,
    recall,
    handleRecall,
    updateDropLabel,
    isNewRow,
    showModal,
    rebalanceNew,
    modifyHedgeNew,
    selectNotification,
    handleSettingPortfolioId,
    hedgePercentBelow,
    hedgePercentAbove,
    handlePortfolioName,
    updatePortfolioName,
    handleUpdatePortfolioName,
    portfolioName,
    handleShowModal,
    existingNameError,
    handleExistingNameError,
    nameLengthError,
    handleNameLengthError,
    handleRebalanceNew,
    isModifyHedge,
    handleIsModifyHedge,
    handleModifyRange,
    handleGetCurrRateValue,
    getCurrRateValue,
    handleDisableButton,
    getIndexData,
    handleUpdateDropdown,
    handleGetIndexData,
    updateModifyHedge,
    updateDropdown,
    editBetaValue,
  } = useHedgingContext();

  const totalHedgeExpo = betaValue * (rangeData / 100);
  const totalUnhedgeExpo = betaValue - totalHedgeExpo;

  const totalPortfolio = tableData?.reduce((acc, cv) => {
    const value = cv.vatableDatalue
      ? parseFloat(cv.value.replace(/,/g, ""))
      : 0;
    return acc + value;
  }, 0);

  const callApi = useCallback(async () => {
    // Fetch data from local storage
    const getData = localStorage.getItem("builderData");
    const getPortfolioId = localStorage.getItem("portfolioId");
    const getPortfolioName = localStorage.getItem("portfolioName");
    const getbuilderData = JSON.parse(getData);
    handleRecall(!recall);

    const holdings = getbuilderData?.holdings?.map((val) => {
      return {
        TICKER: val.TICKER,
        TICKER_NAME: val.TICKER_NAME,
        PRICE: val.PRICE,
        WEIGHT: val.WEIGHT,
        UNITS: val.UNITS,
        VALUE: val.VALUE,
      };
    });

    const hedgeholding = {
      OKTA_ID: user?.sub,
      PORTFOLIO_ID: Number(getPortfolioId),
    };

    const hedgeholdingData = {
      hedgeholding: {
        OKTA_ID: user?.sub,
        PORTFOLIO_ID: Number(getPortfolioId),
        HEDGE_TICKER_ID: getbuilderData?.hedgeHoldingData?.HEDGE_TICKER_ID,
        HEDGE_PERCENT: getbuilderData?.hedgeHoldingData?.HEDGE_PERCENT,
        TOTAL_BETA_EXPOSURE:
          getbuilderData?.hedgeHoldingData?.TOTAL_BETA_EXPOSURE,
        HEDGED_EXPOSURE: getbuilderData?.hedgeHoldingData?.HEDGED_EXPOSURE,
        UNHEDGED_EXPOSURE: getbuilderData?.hedgeHoldingData?.UNHEDGED_EXPOSURE,
        REQUIRED_INVESTMENT:
          getbuilderData?.hedgeHoldingData?.REQUIRED_INVESTMENT,
        HEDGED_TOTAL: getbuilderData?.hedgeHoldingData?.HEDGED_TOTAL,
        UNHEDGED_TOTAL: getbuilderData?.hedgeHoldingData?.UNHEDGED_TOTAL,
        HEDGED_TYPE: getbuilderData?.hedgeHoldingData?.HEDGED_TYPE,
        HEDGED_UNITS: getbuilderData?.hedgeHoldingData?.HEDGED_UNITS,
      },
    };

    // Update builderData with holdings and hedgeholding
    const builderData = {
      holdings: holdings,
      hedgeholding: hedgeholding,
    };

    const postData = {
      OKTA_ID: user?.sub,
      NAME: getPortfolioName,
      RANGE_ABOVE: isNewRow ? 5 : hedgePercentAbove,
      RANGE_BELOW: isNewRow ? 5 : hedgePercentBelow,
      ALERTS: isNewRow ? "No Alerts" : selectNotification,
      ID: isSelectPortfolio,
    };

    const {
      data: addNewHolding,
      status: statusAddNewHolding,
      errors: errorsAddNewHolding,
    } = await mutate(
      apiKeys["singleHolding"](user?.sub, getPortfolioId),
      () => apiPostRequest(`api/saveholdings`, JSON.stringify(builderData)),
      { revalidate: true }
    );

    await mutate(
      apiKeys["singleHolding"](user?.sub, getPortfolioId),
      apiPostRequest(`api/hedgeholdings`, JSON.stringify(hedgeholdingData)),
      { revalidate: true }
    );

    await mutate(
      apiKeys["hedgePortfolioKey"],
      () => apiPutRequest(`api/updateportfolio`, JSON.stringify(postData)),
      {
        revalidate: true,
      }
    );

    if (!isEmpty(addNewHolding) && !addNewHolding?.error) {
      await mutate(
        apiKeys["singleHolding"](user?.sub, getPortfolioId),
        () =>
          apiGetRequest(
            `api/getholdings?oktaid=${user?.sub}&portfolioid=${getPortfolioId}`
          ),
        { revalidate: true }
      );
      await mutate(apiKeys["graphData"](user?.sub, portfolioId), () =>
        apiGetRequest(
          `/api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${portfolioId}`
        )
      );
      handleIsHoldings(false);
      handleIsAlert({
        open: true,
        message: "Your holdings have been saved.",
        error: false,
      });

      router.push(`hedge-builder?portfolio_id=${getPortfolioId}`);
      handleAddNewRow(false);
      handleGetHoldingLoader(false);
      handleInvestmentLoader(false);
      setPortfolioId(0);
      localStorage.removeItem("builderData");
      localStorage.removeItem("percentage");
      setBuilderData(false);
      handleEditHolding(false);
      handleSaveHoldings(false);
      handleRebalanceNew(false);
      handleIsModifyHedge(false);
      handleModifyRange(false);
      handleGetCurrRateValue(false);
      handleDisableButton(false);
      handleUpdateDropdown(false);
      handleGetIndexData("");
    }
  }, [
    handleAddNewRow,
    handleDisableButton,
    handleEditHolding,
    handleGetCurrRateValue,
    handleGetHoldingLoader,
    handleGetIndexData,
    handleInvestmentLoader,
    handleIsAlert,
    handleIsHoldings,
    handleIsModifyHedge,
    handleModifyRange,
    handleRebalanceNew,
    handleRecall,
    handleSaveHoldings,
    handleUpdateDropdown,
    hedgePercentAbove,
    hedgePercentBelow,
    isNewRow,
    isSelectPortfolio,
    portfolioId,
    recall,
    router,
    selectNotification,
    user?.sub,
  ]);

  // create new flow
  const handleCreateAccount = async () => {
    const checkExistingPortfolio = getAllPortfolio?.some(
      (val) => val.NAME === portfolioName.trim()
    );

    const name = !updatePortfolioName
      ? updateDropLabel // `${updateDropLabel} - v2`
      : portfolioName.trim();

    if (name.length > 26) {
      handleNameLengthError(true);
      handleShowModal(true);
      return;
    }

    if (!isAuthenticated) {
      isHoldings && localStorage.setItem("isHolding", true);
      localStorage.setItem("justLogged", true);
      setTimeout(() => {
        loginWithRedirect();
      }, 500); // adjust the delay time as needed
    } else {
      if (checkExistingPortfolio) {
        handleExistingNameError(true);
        handleShowModal(true);
      } else {
        handleGetHoldingLoader(true);
        handleExistingNameError(false);
      }

      if (!checkExistingPortfolio && !nameLengthError) {
        localStorage.removeItem("justLogged");
        handleLoaderShow(true);
        handleInvestmentLoader(true);
        handleSettingPortfolioId(isSelectPortfolio);
        const postData = {
          OKTA_ID: user?.sub,
          NAME: name,
        };

        const {
          data: addNewPortfolio,
          status: statusAddNewPortfolio,
          errors: errorsAddNewPortfolio,
        } = await mutate(
          apiKeys["hedgePortfolioKey"],
          () => apiPostRequest(`api/addportfolio`, JSON.stringify(postData)),
          {
            revalidate: true,
          }
        );

        if (addNewPortfolio?.ID) {
          const { data: allPortfolio, isLoading: isLoadingAllPortfolio } =
            await mutate(apiKeys["hedgePortfolioKey"], () =>
              apiGetRequest(`api/getallportfolio?oktaid=${user?.sub}`)
            );
          if (!isLoadingAllPortfolio) {
            handleAllPortfolio((prev) =>
              Array.isArray(allPortfolio) ? allPortfolio : prev
            );
            handleSelectPortfolio(addNewPortfolio?.ID);
          }

          setPortfolioId(addNewPortfolio?.ID);
          localStorage.setItem("portfolioId", addNewPortfolio?.ID);
          localStorage.removeItem("name");

          await mutate(
            apiKeys["singleHolding"](user?.sub, addNewPortfolio?.ID),
            () =>
              apiGetRequest(
                `api/getholdings?oktaid=${user?.sub}&portfolioid=${addNewPortfolio?.ID}`
              )
          );

          await mutate(
            apiKeys["graphData"](user?.sub, addNewPortfolio?.ID),
            () =>
              apiGetRequest(
                `/api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${addNewPortfolio?.ID}`
              )
          );
          handleLoaderShow(false);
          handleShowModal(false);
        }
        setBuilderData(true);
      }
    }

    //  currentHedgePercentage start //
    const apiExposure =
      getHedgeData?.hedgeholding?.HEDGED_UNITS *
      getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE *
      Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

    const editBeta = editHolding ? editBetaValue : betaValue;

    const currentHedgePercentage = (apiExposure / editBeta) * 100;
    // currentHedgePercentage end //

    const editPercentage = editHolding
      ? currentHedgePercentage
      : Math.floor(getHedgeData?.hedgeholding?.HEDGE_PERCENT);

    const targetHedgeExposure = (editPercentage / 100) * editBeta;

    const requireHedgeHolding =
      targetHedgeExposure /
      Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

    const portfolioType = editHolding
      ? "edit"
      : rebalanceNew
      ? "rebalance"
      : isModifyHedge
      ? "modify"
      : "new";

    const isCheckData = editHolding ? editTableData : tableData;
    const isEditData = editHolding || rebalanceNew || modifyHedgeNew;

    const getCurrRate = await mutate(apiKeys["hedgeTickerListKey"], () =>
      apiGetWithoutTokenRequest(`api/getindexticker?ticker=SH`)
    );
    handleGetCurrRateValue(getCurrRate);

    const tickerData =
      getIndexData?.market_close_value ?? getCurrRate?.market_close_value;

    const holdings = isCheckData
      ?.filter((val) =>
        isEditData && !isNewRow ? val.TICKER !== "" : val.ticker !== ""
      )
      .map((val) => ({
        TICKER: isEditData && !isNewRow ? val.TICKER : val.ticker,
        TICKER_NAME:
          isEditData && !isNewRow ? val.TICKER_NAME : val.ticker_name,
        PRICE: isEditData && !isNewRow ? val.PRICE : val.market_close_value,
        WEIGHT: isEditData && !isNewRow ? val.WEIGHT : val.weight,
        UNITS: editHolding
          ? val.UNITS_POST ?? val.UNITS
          : (rebalanceNew || modifyHedgeNew) && !isNewRow
          ? val.UNITS
          : val.unitPost,
        VALUE: editHolding
          ? val.VALUE_POST ?? Number(val.VALUE.replace(/,/g, ""))
          : (rebalanceNew || modifyHedgeNew) && !isNewRow
          ? val.VALUE
          : val.valuePost,
      }));

    const hedgeHolding = {
      OKTA_ID: "",
      PORTFOLIO_ID: "",
    };

    const roundRequireInvestment = Number(
      (totalHedgeExpo / Math.abs(getTickerBeta)).toFixed(5)
    );

    let REQUIRED_INVESTMENT = roundRequireInvestment;

    if (rebalanceNew || editHolding) {
      REQUIRED_INVESTMENT = requireHedgeHolding;
    }

    let rebalnceCheck;

    if (rebalanceNew || (!updateDropdown && updateModifyHedge)) {
      rebalnceCheck = getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE;
    } else {
      rebalnceCheck = tickerData;
    }

    const hedgeUnits = Number(
      (REQUIRED_INVESTMENT / rebalnceCheck).toFixed(10)
    );

    const requiredInvestmentReblance =
      hedgeUnits * getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE;

    const hedgeHoldingData = {
      OKTA_ID: "",
      PORTFOLIO_ID: "",
      HEDGE_TICKER_ID: selectedIndexId === undefined ? 1 : selectedIndexId,
      HEDGE_PERCENT: editHolding
        ? currentHedgePercentage
        : rebalanceNew
        ? getHedgeData?.hedgeholding?.HEDGE_PERCENT
        : rangeData,
      TOTAL_BETA_EXPOSURE: editHolding
        ? editBetaValue
        : !!betaValue
        ? betaValue
        : 0,
      HEDGED_EXPOSURE: totalHedgeExpo,
      UNHEDGED_EXPOSURE: !!totalUnhedgeExpo ? totalUnhedgeExpo : 0,
      REQUIRED_INVESTMENT: rebalanceNew
        ? requiredInvestmentReblance
        : REQUIRED_INVESTMENT,
      HEDGED_TOTAL:
        totalHedgeExpo / Math.abs(selectedTickerBeta) + totalPortfolio,
      UNHEDGED_TOTAL: totalPortfolio,
      HEDGED_TYPE: portfolioType,
      HEDGED_UNITS: editHolding
        ? getHedgeData?.hedgeholding?.HEDGED_UNITS
        : hedgeUnits,
    };

    const builderData = {
      holdings: [...holdings],
      hedgeholding: hedgeHolding,
      hedgeHoldingData: hedgeHoldingData,
    };

    localStorage.setItem("builderData", JSON.stringify(builderData));
    localStorage.setItem("portfolioName", name);
  };

  useEffect(() => {
    if (!!portfolioId && builderData && recall && getCurrRateValue) {
      callApi();
    }
  }, [builderData, callApi, getCurrRateValue, portfolioId, recall]);

  const changePortfolioName = (e) => {
    handlePortfolioName(e.target.value);
    handleUpdatePortfolioName(true);
    if (e.target.value.length > 26) {
      handleNameLengthError(true);
    } else {
      handleNameLengthError(false);
    }
  };

  const checkInputData = updatePortfolioName
    ? !!portfolioName.trim()
    : !portfolioName.trim();

  const newPortfolio = editHolding || rebalanceNew || modifyHedgeNew;

  return showModal ? (
    <div className={clsx(BASE_CLASS, "absolute")}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[348px] w-[78vw] bg-white py-2 lg:w-[464px]">
          <div className="px-30 pb-24 pt-56">
            <div className="h4 font-MazzardHRegular mb-12 text-dark">
              {`Save ${newPortfolio ? "New" : "your"}  portfolio`}
            </div>
            <div>
              {`${
                newPortfolio
                  ? "Save new holding list based on changes made."
                  : "Create an account to unlock Performance Monitor features!"
              }`}
            </div>
            <div className="mt-28">
              <div>
                <label className="text-2xs">Portfolio Name</label>
                <input
                  type="text"
                  name="portfolio-name"
                  value={
                    isAuthenticated && isTabPressed
                      ? updatePortfolioName
                        ? portfolioName
                        : !isNewRow
                        ? `${updateDropLabel}` //`${updateDropLabel} - v2`
                        : null
                      : null
                  }
                  placeholder={!isNewRow ? `${updateDropLabel}` : null}
                  className="h-[56px] w-full border border-solid border-gray-200 p-8 focus:outline-none"
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      e.preventDefault();
                      setIsTabPressed(true); // Set this flag to true when Tab is pressed
                    }
                  }}
                  onBlur={() => {
                    setIsTabPressed(false); // Reset the flag when focus is lost
                  }}
                  onChange={(e) => {
                    changePortfolioName(e);
                  }}
                  aria-label="Portfolio name"
                />
              </div>
              {existingNameError ? (
                <div className="text-1xs py-12 text-red">
                  {`${"Portfolio Name should be unique"}`}
                </div>
              ) : null}
              {nameLengthError ? (
                <div className="text-1xs py-12 text-red">
                  {`${"Portfolio Name should be less than 26 character"}`}
                </div>
              ) : null}
              <span className="text-3xs text-gray-400">26 character limit</span>
            </div>
            <button
              className={clsx(
                `cta btn btn-primary mt-24 inline-block w-full`,
                `${
                  !checkInputData || (nameLengthError && "cursor-not-allowed")
                }`
              )}
              onClick={handleCreateAccount}
              disabled={!checkInputData || nameLengthError ? true : false}
            >
              {loaderShow ? (
                <ButtonLoader />
              ) : (
                `${newPortfolio ? "Save New" : "Save"}`
              )}
            </button>
            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => {
                  handleModal();
                  handleExistingNameError(false);
                  handleNameLengthError(false);
                  handlePortfolioName("");
                  handleUpdatePortfolioName(false);
                }}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function EditModal(props) {
  const {
    openEditHoldingModal,
    handleEditModal,
    handleHoldingModal,
    handleEditPortfolio,
  } = props;
  const { updateDropLabel, loaderShow } = useHedgingContext();

  return openEditHoldingModal ? (
    <div className={clsx(BASE_CLASS, "absolute")}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[348px] w-[78vw] bg-white py-2 lg:w-[464px]">
          <div className="px-30 pb-24 pt-56">
            <div className="text-md">
              Save your changes to <strong>{updateDropLabel}</strong> or create
              a new holding list based on changes made.
            </div>
            <button
              className="cta btn btn-primary mt-24 inline-block w-full"
              onClick={handleEditPortfolio}
            >
              {loaderShow ? <ButtonLoader /> : "Save"}
            </button>
            <button
              className="cta btn btn-secondary mt-24 inline-block w-full"
              onClick={handleHoldingModal}
            >
              Create New
            </button>

            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => handleEditModal()}
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export const filterData = (data, isSelectedBeta) => {
  const selectBeta = isSelectedBeta?.toLowerCase().split(".").join("_");

  return data
    ?.filter((item) => {
      const betaMap = {
        beta_ndx_indx: item.beta_ndx_indx,
        beta_dja_indx: item.beta_dja_indx,
        beta_gspc_indx: item.beta_gspc_indx,
        beta_iwm: item.beta_iwm,
        beta_eem: item.beta_eem,
        beta_efa: item.beta_efa,
        beta_mid_indx: item.beta_mid_indx,
        beta_afty: item.beta_afty,
      };

      // Find the beta value that matches the isSelectedBeta
      const selectedBetaValue = betaMap[`beta_${selectBeta}`];

      // Check if the selected beta exists and is 0 or undefined
      return (
        item.ticker_name !== "" &&
        (selectedBetaValue === 0 || selectedBetaValue === undefined)
      );
    })
    ?.map((item) => item.ticker_name);
};

export default BuilderTabs;
