import clsx from "clsx";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useAuth0 } from "@auth0/auth0-react";

const BASE_CLASS = "site-authentication";

function SiteAuthentication() {
  const [isCookies, setIsCookies] = useState(false);
  const [password, setPassword] = useState("");

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    const authenticated = Cookies.get("authenticated");
    setIsCookies(authenticated === "true");
  }, []);

  const handlePassword = (e) => {
    const inputPassword = e.target.value;
    setPassword(inputPassword);
  };

  const handleSubmitPassword = () => {
    if (password === "ProShares@123") {
      Cookies.set("authenticated", "true");
      setIsCookies(true);
    } else {
      Cookies.remove("authenticated");
      setIsCookies(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      const timeout = setTimeout(() => {
        loginWithRedirect();
      }, 1000);

      return () => clearTimeout(timeout); // Cleanup the timeout on unmount or dependency change
    }
  }, [isAuthenticated, loginWithRedirect]);

  return !isCookies ? (
    <div
      className={clsx(
        BASE_CLASS,
        "absolute inset-0 z-[9999] hidden bg-white pt-48"
      )}
    >
      <div className="container">
        <div className="flex flex-col items-start gap-16">
          <div className="text-md">Password</div>
          <input
            className="size-full h-48 w-[380px] border bg-transparent p-4"
            value={password}
            onChange={handlePassword}
            aria-label="Value"
            type="password"
          />
          <button
            type="button"
            className="border bg-secondary px-20 py-8 text-md text-white"
            onClick={handleSubmitPassword}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default SiteAuthentication;
