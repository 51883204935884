import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { apiKeys } from "@/lib/regex";
import { apiGetRequest } from "@/lib/api";
import { useHedgingContext } from "@/context/betaContext";
import isEmpty from "lodash.isempty";
import { useEffect, useRef } from "react";
import { formatDate } from "@/lib/text";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function BarGraph(props) {
  const {
    isSelectPortfolio,
    getAllPortfolio,
    graphData,
    handleGraphData,
    getHedgeData,
  } = useHedgingContext();
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  const getPortfolioId = localStorage.getItem("portfolio_Id");

  const { data: graph, isLoading: isLoadingGraph } = useSWR(
    isAuthenticated && !isEmpty(getHedgeData?.hedgeholding)
      ? apiKeys["graphData"](
          user?.sub,
          isSelectPortfolio ? isSelectPortfolio : getAllPortfolio?.[0]?.ID
        )
      : null,
    () =>
      apiGetRequest(
        `api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${
          !!getPortfolioId
            ? Number(getPortfolioId)
            : isSelectPortfolio
            ? isSelectPortfolio
            : getAllPortfolio?.[0]?.ID
        }`
      )
  );

  useEffect(() => {
    if (!isLoadingGraph && !isEmpty(graph)) {
      handleGraphData(graph);
    }
  }, [graph, handleGraphData, isLoadingGraph]);

  const sortPerformance =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData?.sort(
          (a, b) =>
            new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
        )
      : null;

  // const labels = [
  //   `As of: ${formatDate(sortPerformance?.[0]?.MARKET_CLOSE_DATE)}`,

  //   `As of: ${formatDate(
  //     sortPerformance?.[sortPerformance.length - 1]?.MARKET_CLOSE_DATE
  //   )}`,
  // ];

  const labels = [
    // `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`,

    // `As of: ${formatDate(
    //   sortPerformance?.[0]?.CREATED_DATE ===
    //     sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE
    //     ? addDays(
    //         sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE,
    //         1
    //       )
    //     : sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE
    // )}`,
    `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`,

    sortPerformance?.length < 2
      ? `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`
      : `As of: ${formatDate(
          sortPerformance?.[sortPerformance?.length - 1]?.CREATED_DATE
        )}`,
  ];

  const HEDGEDEXPOSURE = Math.floor(
    sortPerformance?.[0]?.CURRENT_HEDGE_EXPOSURE
  );

  const UNHEDGED_EXPOSURE = Math.floor(
    sortPerformance?.[0]?.CURRENT_UNHEDGE_EXPOSURE
  );

  // const second_UNHEDGED_EXPOSURE = Math.ceil(
  //   sortPerformance?.[sortPerformance.length - 1]?.CURRENT_UNHEDGE_EXPOSURE
  // );

  // const second_UNHEDGED_EXPOSURE = Math.floor(
  //   sortPerformance?.[sortPerformance.length - 1]?.TOTALBETAEXPOSURE -
  //     sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_EXPOSURE
  // );

  // const CURRENT_HEDGE_EXPOSURE = Math.floor(
  //   sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_EXPOSURE
  // );

  const First_obj_CURRENT_UNHEDGE_EXPOSURE = Math.floor(
    sortPerformance?.[0]?.CURRENT_UNHEDGE_EXPOSURE
  );

  const First_obj_CURRENT_HEDGED_EXPOSURE = Math.floor(
    sortPerformance?.[0]?.CURRENT_HEDGE_EXPOSURE
  );

  const Last_obj_CURRENT_UNHEDGE_EXPOSURE = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_UNHEDGE_EXPOSURE
  );

  const Last_obj_CURRENT_HEDGE_EXPOSURE = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_EXPOSURE
  );

  const initialPercentage = useRef(0);

  useEffect(() => {
    initialPercentage.current = Math.floor(
      HEDGEDEXPOSURE &&
        (HEDGEDEXPOSURE / (HEDGEDEXPOSURE + UNHEDGED_EXPOSURE)) * 100
    );
  }, [HEDGEDEXPOSURE, UNHEDGED_EXPOSURE]);

  // Check if MARKET_CLOSE_DATE is valid
  const givenDate =
    sortPerformance?.length > 0 &&
    !isNaN(new Date(sortPerformance[0]?.MARKET_CLOSE_DATE))
      ? new Date(sortPerformance[0]?.MARKET_CLOSE_DATE)
      : null;
  if (givenDate) {
    givenDate?.setDate(givenDate?.getDate() + 1);
  }

  // const givenDate =
  //   sortPerformance && new Date(sortPerformance?.[0]?.MARKET_CLOSE_DATE);
  // givenDate?.setDate(givenDate?.getDate() + 1);

  // Current date
  const currentDate = new Date();

  // purple bar
  const dataHedgedExposure =
    givenDate?.toISOString().split("T")[0] ===
    currentDate?.toISOString().split("T")[0]
      ? [HEDGEDEXPOSURE, Last_obj_CURRENT_HEDGE_EXPOSURE]
      : [HEDGEDEXPOSURE, Last_obj_CURRENT_HEDGE_EXPOSURE];

  // blue bar calculation curr-unhedge - curr-hedge

  const first_Obj =
    First_obj_CURRENT_UNHEDGE_EXPOSURE - First_obj_CURRENT_HEDGED_EXPOSURE;

  const last_obj =
    Last_obj_CURRENT_UNHEDGE_EXPOSURE - Last_obj_CURRENT_HEDGE_EXPOSURE;

  // blue bar
  const dataUnhedgedExposure =
    givenDate?.toISOString().split("T")[0] ===
    currentDate?.toISOString().split("T")[0]
      ? [first_Obj, sortPerformance?.length <= 1 ? first_Obj : last_obj]
      : [first_Obj, last_obj];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Hedged Exposure",
        data: dataHedgedExposure,
        backgroundColor: "#771B85",
        barPercentage: 0.97,
        categoryPercentage: 0.97,
      },
      {
        label: "Unhedged Exposure",
        data: dataUnhedgedExposure,
        backgroundColor: "#415580",
        barPercentage: 0.97,
        categoryPercentage: 0.97,
      },
    ],
  };

  // const topLabel = {
  //   id: "topLabel",
  //   afterDatasetsDraw(chart, args, pluginOptions) {
  //     const {
  //       ctx,
  //       scales: { x, y },
  //     } = chart;
  //     chart.data.datasets[0].data.forEach((dataPoint, index) => {
  //       const datasetArray = [];
  //       chart.data.datasets.forEach((dataset) => {
  //         datasetArray.push(dataset.data[index]);
  //       });

  //       // sum array
  //       function totalSum(total, values) {
  //         return Math.ceil(total + values);
  //       }
  //       let sum = `$${datasetArray.reduce(totalSum, 0).toLocaleString()}`;

  //       // const sum = datasetArray.reduce(totalSum, 0);
  //       // const displayText =
  //       //   index === 0
  //       //     ? `$${sum.toLocaleString()} (${initialPercentage.current > 0 ? initialPercentage.current : "0"}%)`
  //       //     : `$${sum.toLocaleString()}`;

  //       ctx.font = "mazzardSemiBold";
  //       ctx.fillStyle = "black";
  //       ctx.textAlign = "center";
  //       ctx.fillText(
  //         sum,
  //         x.getPixelForValue(index),
  //         chart.getDatasetMeta(1).data[index].y - 10
  //       );
  //     });
  //   },
  // };

  const topLabel = {
    id: "topLabel",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        scales: { x, y },
      } = chart;
      chart.data.datasets[0].data.forEach((dataPoint, index) => {
        const datasetArray = [];
        chart.data.datasets.forEach((dataset) => {
          datasetArray.push(dataset.data[index]);
        });

        // sum array
        function totalSum(total, values) {
          return Math.ceil(total + values);
        }
        let sum = `$${datasetArray.reduce(totalSum, 0).toLocaleString()}`;

        // Get the Y position for the text
        const datasets = chart.data.datasets;
        const firstDataset = chart.getDatasetMeta(0).data[index];
        const secondDataset = chart.getDatasetMeta(1).data[index];

        // Determine the total bar height
        const totalHeight =
          Math.abs(firstDataset.y - firstDataset.base) +
          Math.abs(secondDataset.y - secondDataset.base);

        // Determine Y position based on total bar height and whether the sum is negative
        let yPosition;
        const sum_numeric = datasetArray.reduce(totalSum, 0);

        if (sum_numeric < 0) {
          // If sum is negative, place above the bar
          yPosition = Math.min(firstDataset.y, secondDataset.y) - 20;
        } else {
          // If sum is positive or zero, place below the top of the bar
          yPosition = Math.min(firstDataset.y, secondDataset.y) - 10;
        }

        ctx.font = "mazzardSemiBold";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(sum, x.getPixelForValue(index), yPosition);
      });
    },
  };

  const options = {
    animation: {
      duration: 0, // Disable animation to ensure immediate rendering
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || "";
            let value = tooltipItem.raw !== undefined ? tooltipItem.raw : 0;
            // previous for %
            const hedgedValue =
              tooltipItem.chart.data.datasets[0].data[tooltipItem.dataIndex];
            const unhedgedValue =
              tooltipItem.chart.data.datasets[1].data[tooltipItem.dataIndex];

            const totalValue = hedgedValue + unhedgedValue;
            const percentage = (value / totalValue) * 100;
            const formattedPercentage = `(${Math.round(percentage)}%)`;

            const formattedValue = `$${Math.abs(value).toLocaleString()}`;
            const sign = value < 0 ? "-" : "";
            return `${label}: ${sign}${formattedValue} ${formattedPercentage}`;
            // return `${label}: ${sign}${formattedValue}`;
          },
        },
      },
      legend: {
        display: false,
        position: "top",
        align: "start",
      },
      datalabels: {
        display: true,
        color: "white",
        display: function (context) {
          const datasetMeta = context.chart.getDatasetMeta(
            context.datasetIndex
          );
          const barElement = datasetMeta.data[context.dataIndex];

          // Calculate the height of the bar
          const barHeight = Math.abs(barElement.base - barElement.y);

          // Define a threshold for the height below which the label will be hidden
          // current
          const heightThreshold = 40; // 20

          // Show the label only if the height is greater than the threshold
          return barHeight > heightThreshold;
        },
        font: {
          weight: "bold",
        },
        // formatter: (value) => `$${value?.toLocaleString()}`,
        formatter: (value, context) => {
          // previous for %
          const hedgedValue =
            context.chart.data.datasets[0].data[context.dataIndex];
          const unhedgedValue =
            context.chart.data.datasets[1].data[context.dataIndex];

          const totalExposure = hedgedValue + unhedgedValue;

          const percentage = (value / totalExposure) * 100;

          // return `$${value.toLocaleString()}\n ${percentage.toFixed(1)}%`;
          return `$${value.toLocaleString()}\n (${Math.round(percentage)}%)`;
          // return `$${value.toLocaleString()}`;
        },
        textAlign: "center",
      },
    },
    layout: {
      padding: {
        top: 40,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,

        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line on y-axis
        },
        ticks: {
          color: "#323232", // Set x-axis ticks color
          font: {
            family: "MazzardHBold",
            size: 14,
            lineHeight: 1.5,
          },
        },
      },
      y: {
        stacked: true,

        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line on y-axis
        },
        ticks: {
          display: false,
          callback: (value) => `$${value.toLocaleString()}`,
        },
      },
    },
  };

  // const options = {
  //   animation: {
  //     duration: 0, // Disable animation to ensure immediate rendering
  //   },
  //   plugins: {
  //     tooltip: {
  //       mode: "index",
  //       intersect: false,
  //       itemSort: function (a, b) {
  //         return b.datasetIndex - a.datasetIndex;
  //       },
  //       callbacks: {
  //         label: function (tooltipItem) {
  //           const label = tooltipItem.dataset.label || "";
  //           let value = tooltipItem.raw !== undefined ? tooltipItem.raw : 0;
  //           const formattedValue = `$${Math.abs(value).toLocaleString()}`;
  //           const sign = value < 0 ? "-" : "";
  //           return `${label}: ${sign}${formattedValue}`;
  //         },
  //       },
  //     },
  //     legend: {
  //       display: false,
  //       position: "top",
  //       align: "start",
  //     },
  //     datalabels: {
  //       display: true,
  //       color: "white",
  //       display: function (context) {
  //         const datasetMeta = context.chart.getDatasetMeta(
  //           context.datasetIndex
  //         );
  //         const barElement = datasetMeta.data[context.dataIndex];

  //         // Calculate the height of the bar
  //         const barHeight = Math.abs(barElement.base - barElement.y);

  //         // Define a threshold for the height below which the label will be hidden
  //         const heightThreshold = 20;

  //         // Show the label only if the height is greater than the threshold
  //         return barHeight > heightThreshold;
  //       },
  //       font: {
  //         weight: "bold",
  //       },
  //       formatter: (value, context) => {
  //         // Get both datasets
  //         const hedgedValue =
  //           context.chart.data.datasets[0].data[context.dataIndex];
  //         const unhedgedValue =
  //           context.chart.data.datasets[1].data[context.dataIndex];

  //         // Calculate total exposure
  //         const totalExposure = hedgedValue + unhedgedValue;

  //         // Calculate percentage for this dataset
  //         const percentage = (value / totalExposure) * 100;

  //         // Display the value and percentage
  //         return `$${value.toLocaleString()}\n ${percentage.toFixed(1)}%`;
  //       },
  //       textAlign: "center",
  //     },
  //   },
  //   layout: {
  //     padding: {
  //       top: 40,
  //     },
  //   },
  //   responsive: true,
  //   scales: {
  //     x: {
  //       stacked: true,
  //       grid: {
  //         display: false,
  //       },
  //       border: {
  //         display: false, // Hide the border line on y-axis
  //       },
  //       ticks: {
  //         color: "#323232", // Set x-axis ticks color
  //         font: {
  //           family: "MazzardHBold",
  //           size: 14,
  //           lineHeight: 1.5,
  //         },
  //       },
  //     },
  //     y: {
  //       stacked: true,
  //       grid: {
  //         display: false,
  //       },
  //       border: {
  //         display: false, // Hide the border line on y-axis
  //       },
  //       ticks: {
  //         display: false,
  //         callback: (value) => `$${value.toLocaleString()}`,
  //       },
  //     },
  //   },
  // };

  return (
    <div className="container bg-gray-100 text-left">
      <div className="pl-16 pt-8 font-mazzardSemiBold text-md text-dark">
        Exposure Value
      </div>
      <div className="pb-8 pl-16">
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-grey-600"></span>
          <span className="px-8 text-3xs text-grey-600">Unhedged Exposure</span>
        </span>
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-secondary"></span>
          <span className="b-10 px-8 text-3xs text-secondary">
            Hedged Exposure
          </span>
        </span>
      </div>
      <Bar data={data} options={options} plugins={[topLabel]} />
      {/* <div className="text-center text-3xs">
        Exposure uses your holdings weighted beta average to calculate your beta
        value exposure.
      </div> */}
    </div>
  );
}

export default BarGraph;
