import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useHedgingContext } from "@/context/betaContext";
import isEmpty from "lodash.isempty";
import { formatDate } from "@/lib/text";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function BarGraphHoldings(props) {
  const { graphData } = useHedgingContext();

  const sortPerformance =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData?.sort(
          (a, b) =>
            new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
        )
      : [];

  // const labels = [
  //   `As of: ${formatDate(sortPerformance?.[0].MARKET_CLOSE_DATE)}`,
  //   `As of: ${formatDate(sortPerformance?.[sortPerformance.length - 1].MARKET_CLOSE_DATE)}`,
  // ];

  const labels = [
    // `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`,

    // `As of: ${formatDate(
    //   sortPerformance?.[0]?.CREATED_DATE ===
    //     sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE
    //     ? addDays(
    //         sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE,
    //         1
    //       )
    //     : sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE
    // )}`,
    `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`,

    sortPerformance?.length < 2
      ? `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`
      : `As of: ${formatDate(
          sortPerformance?.[sortPerformance?.length - 1]?.CREATED_DATE
        )}`,
  ];

  const HEDGED_TOTAL = Math.floor(sortPerformance?.[0]?.UNHEDGED_TOTAL);

  const UNHEDGED_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  const REQUIREDINVESTMENT = Math.floor(
    sortPerformance?.[0]?.REQUIREDINVESTMENT
  );

  const second_HEDGED_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  const second_REQUIREDINVESTMENT = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL -
      sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  const CURRENT_HEDGE_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL
  );

  const First_Obj_UNHEDGED_TOTAL = Math.floor(
    sortPerformance?.[0]?.UNHEDGED_TOTAL
  );

  const Last_Obj_UNHEDGED_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  const First_Obj_REQUIREDINVESTMENT = Math.floor(
    sortPerformance?.[0]?.CURRENT_HEDGE_TOTAL -
      sortPerformance?.[0]?.UNHEDGED_TOTAL
  );

  const First_Obj_CURRENT_HEDGE_TOTAL = Math.floor(
    sortPerformance?.[0]?.CURRENT_HEDGE_TOTAL
  );

  const Last_Obj_CURRENT_HEDGE_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL
  );

  // Check if MARKET_CLOSE_DATE is valid
  const givenDate =
    sortPerformance?.length > 0 &&
    !isNaN(new Date(sortPerformance[0]?.MARKET_CLOSE_DATE))
      ? new Date(sortPerformance[0]?.MARKET_CLOSE_DATE)
      : null;

  if (givenDate) {
    givenDate?.setDate(givenDate?.getDate() + 1);
  }

  // const givenDate = new Date(sortPerformance[0]?.MARKET_CLOSE_DATE);
  // givenDate?.setDate(givenDate?.getDate() + 1);

  // Current date
  const currentDate = new Date();

  // const sumOfStart = REQUIREDINVESTMENT + HEDGED_TOTAL;
  // const sumOfCurrent = CURRENT_HEDGE_TOTAL + second_HEDGED_TOTAL;

  // const required = sumOfStart - HEDGED_TOTAL;
  // const CurrentRequired = sumOfCurrent - second_HEDGED_TOTAL;

  const diffCurrent = First_Obj_CURRENT_HEDGE_TOTAL - First_Obj_UNHEDGED_TOTAL;

  const hedgeFirst =
    Math.floor(sortPerformance?.[0]?.CURRENT_HEDGE_TOTAL) -
    Math.floor(sortPerformance?.[0]?.UNHEDGED_TOTAL);

  const hedgelast =
    Math.floor(
      sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL
    ) -
    Math.floor(sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL);

  // const dataHoldingsRequiredInvestment =
  //   givenDate?.toISOString().split("T")[0] ===
  //   currentDate?.toISOString().split("T")[0]
  //     ? [
  //         getHedgeData?.hedgeholding?.HEDGED_TYPE === "edit"
  //           ? diffCurrent
  //           : REQUIREDINVESTMENT,
  //         getHedgeData?.hedgeholding?.HEDGED_TYPE === "edit"
  //           ? diffCurrent
  //           : REQUIREDINVESTMENT,
  //       ]
  //     : getHedgeData?.hedgeholding?.HEDGED_TYPE === "edit"
  //       ? [
  //           getHedgeData?.hedgeholding?.HEDGED_TYPE === "edit"
  //             ? diffCurrent
  //             : REQUIREDINVESTMENT,
  //           hedgelast,
  //         ]
  //       : [
  //           getHedgeData?.hedgeholding?.HEDGED_TYPE === "edit"
  //             ? diffCurrent
  //             : REQUIREDINVESTMENT,
  //           hedgelast,
  //         ];

  const diffCurrentLast =
    Last_Obj_CURRENT_HEDGE_TOTAL - Last_Obj_UNHEDGED_TOTAL;

  // purple bar

  const dataHoldingsRequiredInvestment =
    givenDate?.toISOString().split("T")[0] ===
    currentDate?.toISOString().split("T")[0]
      ? [diffCurrent, sortPerformance?.length <= 1 ? hedgeFirst : hedgelast]
      : [diffCurrent, hedgelast];

  // blue bar

  const dataHedgeTotal =
    givenDate?.toISOString().split("T")[0] ===
    currentDate?.toISOString().split("T")[0]
      ? [
          HEDGED_TOTAL,
          sortPerformance?.length <= 1 ? HEDGED_TOTAL : second_HEDGED_TOTAL,
        ]
      : [
          HEDGED_TOTAL,
          sortPerformance?.length <= 1 ? HEDGED_TOTAL : second_HEDGED_TOTAL,
        ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Hedge",
        data: dataHoldingsRequiredInvestment,
        backgroundColor: "#771B85",
        barPercentage: 0.97,
        categoryPercentage: 0.97,
      },
      {
        label: "Holdings",
        data: dataHedgeTotal,
        backgroundColor: "#415580 ",
        barPercentage: 0.97,
        categoryPercentage: 0.97,
      },
    ],
  };

  // const topLabel = {
  //   id: "topLabel",
  //   afterDatasetsDraw(chart, args, pluginOptions) {
  //     const {
  //       ctx,
  //       scales: { x, y },
  //     } = chart;

  //     chart.data.datasets[0].data.forEach((dataPoint, index) => {
  //       const datasetArray = [];
  //       chart.data.datasets.forEach((dataset) => {
  //         datasetArray.push(dataset.data[index]);
  //       });

  //       //sum array
  //       function totalSum(total, values) {
  //         return Math.floor(total + values);
  //       }
  //       let sum = `$${datasetArray.reduce(totalSum, 0).toLocaleString()}`;
  //       ctx.font = "mazzardSemiBold";
  //       ctx.fillStyle = "black";
  //       ctx.textAlign = "center";
  //       ctx.fillText(
  //         sum,
  //         x.getPixelForValue(index),
  //         chart.getDatasetMeta(1).data[index].y - 10
  //       );
  //     });
  //   },
  // };

  const topLabel = {
    id: "topLabel",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        scales: { x, y },
      } = chart;
      chart.data.datasets[0].data.forEach((dataPoint, index) => {
        const datasetArray = [];
        chart.data.datasets.forEach((dataset) => {
          datasetArray.push(dataset.data[index]);
        });

        // sum array
        function totalSum(total, values) {
          return Math.ceil(total + values);
        }
        let sum = `$${datasetArray.reduce(totalSum, 0).toLocaleString()}`;

        // Get the Y position for the text
        const datasets = chart.data.datasets;
        const firstDataset = chart.getDatasetMeta(0).data[index];
        const secondDataset = chart.getDatasetMeta(1).data[index];

        // Determine the total bar height
        const totalHeight =
          Math.abs(firstDataset.y - firstDataset.base) +
          Math.abs(secondDataset.y - secondDataset.base);

        // Determine Y position based on total bar height and whether the sum is negative
        let yPosition;
        const sum_numeric = datasetArray.reduce(totalSum, 0);

        if (sum_numeric < 0) {
          // If sum is negative, place above the bar
          yPosition = Math.min(firstDataset.y, secondDataset.y) - 20;
        } else {
          // If sum is positive or zero, place below the top of the bar
          yPosition = Math.min(firstDataset.y, secondDataset.y) - 10;
        }

        ctx.font = "mazzardSemiBold";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(sum, x.getPixelForValue(index), yPosition);
      });
    },
  };

  const options = {
    animation: {
      duration: 0, // Disable animation for immediate rendering
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || "";
            let value = tooltipItem.raw !== undefined ? tooltipItem.raw : 0;
            // previous for %
            const hedgedValue =
              tooltipItem.chart.data.datasets[0].data[tooltipItem.dataIndex];
            const unhedgedValue =
              tooltipItem.chart.data.datasets[1].data[tooltipItem.dataIndex];
            const totalValue = hedgedValue + unhedgedValue;

            const percentage = (value / totalValue) * 100;
            const formattedPercentage = `(${Math.round(percentage)}%)`;
            const formattedValue = `$${Math.abs(value).toLocaleString()} `;

            const sign = value < 0 ? "-" : "";
            return `${label}: ${sign}${formattedValue} ${formattedPercentage}`;
            // return `${label}: ${sign}${formattedValue}`;
          },
        },
      },
      legend: {
        display: false,
        position: "top",
        align: "start",
      },
      datalabels: {
        display: function (context) {
          const datasetMeta = context.chart.getDatasetMeta(
            context.datasetIndex
          );
          const barElement = datasetMeta.data[context.dataIndex];

          const barHeight = Math.abs(barElement.base - barElement.y);

          // current
          const heightThreshold = 40; // 20

          return barHeight > heightThreshold;
        },
        color: "white",
        font: {
          weight: "bold",
        },
        // formatter: (value) => `$${value?.toLocaleString()}`,
        textAlign: "center", // Ensure the text is centered
        formatter: (value, context) => {
          // previous for %
          const hedgedValue =
            context.chart.data.datasets[0].data[context.dataIndex];
          const unhedgedValue =
            context.chart.data.datasets[1].data[context.dataIndex];
          const totalExposure = hedgedValue + unhedgedValue;
          const percentage = (value / totalExposure) * 100;
          // return `$${value.toLocaleString()}\n${percentage.toFixed(1)}%`;
          return `$${value.toLocaleString()}\n(${Math.round(percentage)}%)`;
          // return `$${value.toLocaleString()}`;
        },
      },
      afterRender: function (chart) {
        chart.update(); // Forces immediate update after render to ensure data labels are shown.
      },
    },
    layout: {
      padding: {
        top: 40,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,

        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line on y-axis
        },
        ticks: {
          color: "#323232", // Set x-axis ticks color
          font: {
            family: "MazzardHBold",
            size: 14,
            lineHeight: 1.5,
          },
        },
      },
      y: {
        stacked: true,

        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line on y-axis
        },
        ticks: {
          display: false,
          callback: (value) => `$${value.toLocaleString()}`,
        },
      },
    },
  };

  return (
    <div className="container bg-gray-100 text-left">
      <div className="pl-16 pt-8 font-mazzardSemiBold text-md text-dark">
        Portfolio Value
      </div>
      <div className="pb-8 pl-16">
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-grey-600"></span>
          <span className="px-8 text-3xs text-grey-600">Value Ex-Hedge</span>
        </span>
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-secondary"></span>
          <span className="b-10 px-8 text-3xs text-secondary">Hedge Value</span>
        </span>
      </div>
      <Bar data={data} options={options} plugins={[topLabel]} />
      {/* <div className="text-center text-3xs">
        Exposure uses your holdings weighted beta average to calculate your beta
        value exposure.
      </div> */}
    </div>
  );
}

export default BarGraphHoldings;
