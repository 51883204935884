import clsx from "clsx";
import { useWindowSize } from "react-use";

import isEmpty from "lodash.isempty";
import includes from "lodash.includes";
import map from "lodash.map";
import get from "lodash.get";

import HtmlFragment from "./html-fragment";

const BASE_CLASS = "table-block";

function Table(props) {
  let {
    className = "",
    tableList,
    description,
    bgTableMobile,
    bgTableDesktop,
  } = props;
  const mobile = get(useWindowSize(), "width") < 576 ? true : false;

  const trackTable = includes(className, ["track-table"]);
  const bgImage = includes(className, ["bg-image"]);

  console.log("noteVector : ", bgTableDesktop, bgTableMobile, mobile);

  return !isEmpty(tableList) ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div
        style={{
          backgroundImage: mobile
            ? `url(${bgTableMobile.url})`
            : `url(${bgTableDesktop.url})`,
        }}
        className={clsx(
          "mb-24 mt-0 ipad:mb-48 ipad:mt-24",
          mobile && bgImage
            ? "bg-cover bg-right bg-no-repeat"
            : "bg-cover bg-right bg-no-repeat"
        )}
      >
        <div className="container ">
          <div className="grid grid-cols-12 items-center gap-16 ">
            <div className="col-span-12 ipad:col-span-8">
              {!isEmpty(tableList) ? (
                <TableData tableList={tableList} trackTable={trackTable} />
              ) : null}
            </div>
            <div className="col-span-12 ipad:col-span-4">
              {description ? (
                <div className="px-16 pb-24 pt-16 md:px-4 md:py-0 ipad:pl-64 ipad:pr-36">
                  <div className="text-md text-dark">
                    <HtmlFragment>{description}</HtmlFragment>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function TableData(props) {
  const { trackTable, tableList } = props;
  tableList.filter((f) => {
    "contentType" in f ? delete f.contentType : null;
    "name" in f ? delete f.name : null;
    return f;
  });
  const headings = Object.values(tableList[0]);
  const rows = tableList?.filter((f, i) => i !== 0);

  const renderHeadingRow = (heading, index) => {
    return <Cell key={`heading-${index}`} content={heading} header={true} />;
  };

  const renderRow = (row, rowIndex, array) => {
    const keys = Object.keys(row);

    return (
      <tr
        className={clsx(
          {
            "align-top odd:bg-grey-300 even:bg-grey-200 ipad:align-middle":
              trackTable,
          },
          "text-dark"
        )}
        key={`row-${rowIndex}`}
      >
        {map(keys, (key) => {
          return (
            <Cell
              key={key}
              rowIndex={rowIndex}
              array={array}
              content={row[key]}
            />
          );
        })}
      </tr>
    );
  };

  return (
    <table className="table-auto" tabIndex="0">
      <thead
        className={clsx(
          { "bg-secondary": trackTable },
          "p-8 text-xs text-white"
        )}
      >
        <tr key="heading">{map(headings, renderHeadingRow)}</tr>
      </thead>
      <tbody>{map(rows, renderRow)}</tbody>
    </table>
  );
}

function Cell({ content, header, rowIndex, array }) {
  return (
    <td
      className={clsx(
        { "text-body-lg font-mazzardSemiBold": header },
        { "text-body-sm": !header },
        "border-white px-20 py-12 odd:font-mazzardSemiBold even:border-l-2",
        { "ipad:whitespace-nowrap": rowIndex === array?.length - 1 }
      )}
    >
      <HtmlFragment>{content}</HtmlFragment>
    </td>
  );
}

export default Table;
