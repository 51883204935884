// This is for modify hedge percentage calculations
export const modifyHedgePercent = (getHedgeData) => {
  // Calculate apiExposure using hedgeholding data
  const apiExposure =
    getHedgeData?.hedgeholding?.HEDGED_UNITS *
    getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE *
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  // Get the selected beta ticker
  const isSelectedBeta2 = getHedgeData?.hedgeholding?.INDEX_TICKER;

  // Calculate apiBetaValue based on holdings data
  const apiBetaValue = getHedgeData?.holdings?.reduce((acc, curr) => {
    // Check if VALUE is a string before applying replace, then parse to float
    const value =
      typeof curr.VALUE === "string"
        ? parseFloat(curr.VALUE.replace(/,/g, "") || 0)
        : parseFloat(curr.VALUE || 0);

    // Check if the selected beta field exists in the current object
    if (curr.hasOwnProperty(`beta_${isSelectedBeta2?.toLowerCase()}`)) {
      const selectedBeta = parseFloat(
        curr[`beta_${isSelectedBeta2?.toLowerCase()}`] || 0
      );
      return acc + value * selectedBeta;
    }
    return acc;
  }, 0);

  // Calculate the current hedge percentage
  const currentHedgePercentage = (apiExposure / apiBetaValue) * 100;
  return currentHedgePercentage;
};

// ---------------------------------------------------------------------------------------------------------------------------------//

// This is for negative beta error message
export const negativeBetaError = (getHedgeData, editTableData) => {
  const isSelectedBeta = getHedgeData?.hedgeholding?.INDEX_TICKER;
  const editData = editTableData?.filter((val) => val.beta_gspc_indx);

  // This is for save Holdings button (if total is negative then button is disable)
  const totalEditBetaValue = editData?.reduce((acc, curr) => {
    // Check if VALUE is a string before applying replace
    const value =
      typeof curr.VALUE === "string"
        ? parseFloat(curr.VALUE.replace(/,/g, "") || 0)
        : parseFloat(curr.VALUE || 0);

    // Check if the selected beta field exists in the current object
    if (curr.hasOwnProperty(`beta_${isSelectedBeta?.toLowerCase()}`)) {
      const selectedBeta = parseFloat(
        curr[`beta_${isSelectedBeta?.toLowerCase()}`] || 0
      );
      return acc + value * selectedBeta;
    }
  }, 0);
  return totalEditBetaValue;
};

// This is updated data depend on rate
export const updatedPortfolioData = (getHedgeData) => {
  const uniqueData = [
    ...new Map(getHedgeData?.map((item) => [item.TICKER, item])).values(),
  ];

  if (!uniqueData || !Array.isArray(uniqueData)) return []; // Handle invalid or undefined input

  const data = uniqueData.reduce((acc, cv, _, array) => {
    const cloned = { ...cv }; // Clone the current object to avoid mutating the original

    cloned.VALUE = cloned.CURRENT_PRICE * cloned.UNITS;

    if (array.length === acc.length + 1) {
      const totalSum = acc.reduce(
        (sum, item) => sum + item.VALUE,
        cloned.VALUE
      );

      acc.forEach((item) => {
        item.WEIGHT = ((item.VALUE / totalSum) * 100).toFixed(2);
      });

      cloned.WEIGHT = ((cloned.VALUE / totalSum) * 100).toFixed(2);
    }

    acc.push(cloned); // Push the updated clone to the accumulator
    return acc;
  }, []); // Start with an empty array as the accumulator

  return data;
};
