import { useState } from "react";
import { mutate } from "swr";
import clsx from "clsx";

import isEmpty from "lodash.isempty";

import { LineTitle, Tooltip, Icon, HtmlFragment as HF } from "./*";
import { useHedgingContext } from "@/context/betaContext";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDecimalNumber, formatToSlashDate } from "../lib/text";
import { updatedPortfolioData } from "@/lib/formulas";

import {
  apiPostRequest,
  apiGetRequest,
  apiGetWithoutTokenRequest,
} from "@/lib/api";
import { apiKeys } from "@/lib/regex";
import { ButtonLoader, SingleLoader } from "@/lib/loaders";

const BASE_CLASS = "rebalance";

function Rebalancing() {
  const [openModal, setModal] = useState(false);
  const [holdingsTitle, setHoldingsTitle] = useState("");

  const { user } = useAuth0();

  const {
    getHedgeData,
    hedgePercentage,
    currentHedgeValue,
    investmentLoader,
    editHolding,
    betaValue,
    handleShowModal,
    handleRebalanceNew,
    handleTableData,
    handleRecall,
    getAllPortfolio,
    handleLoaderShow,
    rangeData,
    editTableData,
    isSelectPortfolio,
    getTickerBeta,
    rebalanceNew,
    handleGetCurrRateValue,
    getIndexData,
    updateDropdown,
    updateModifyHedge,
    selectedIndexId,
    isRequiredInvestment,
    hedgeTotal,
    tableData,
    handleIsAlert,
    handleChangeEditLabel,
    handleIsHoldings,
    handleEditHolding,
    handleSaveHoldings,
    editBetaValue,
    handleUpdateModifyHedge,
    handleHedgeModal,
    handleModifyHedge,
    handleNewInverseEtf,
    handleIsModifyHedge,
    handleSelectIndex,
    handleResetCode,
    isModifingHedge,
    handleIsModifingHedge,
  } = useHedgingContext();

  const [openEditHoldingModal, setEditHoldingModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);

  const targetHedgeExposure =
    (getHedgeData?.hedgeholding?.HEDGE_PERCENT / 100) * betaValue;

  const requireHedgeHolding =
    targetHedgeExposure /
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  const handleModal = () => {
    setModal((prev) => !prev);
  };

  const handleEditModal = () => {
    setEditHoldingModal((prev) => !prev);
    // handleModal();
    setModal(false);
  };

  const handleHoldingModal = () => {
    // setHoldingModal((prev) => !prev);

    // setModal((prev) => !prev);
    setEditHoldingModal(false);
  };

  const getPercentage = parseFloat(localStorage.getItem("percentage")) || 0;
  const updatedData = updatedPortfolioData(getHedgeData?.holdings);

  const handleRebalancePortfolio = async () => {
    handleLoaderShow(true);
    setModal(false);

    const totalHedgeExpo =
      betaValue * (getHedgeData?.hedgeholding?.HEDGE_PERCENT / 100);

    const totalUnhedgeExpo = betaValue - totalHedgeExpo;

    const totalPortfolio = editTableData?.reduce((acc, cv) => {
      return acc + parseFloat(cv.VALUE || 0);
    }, 0);

    const holdings = updatedData
      ?.filter((val) => val?.TICKER !== "")
      ?.map((val) => ({
        TICKER: val?.TICKER,
        TICKER_NAME: val?.TICKER_NAME,
        PRICE: val?.CURRENT_PRICE,
        WEIGHT: val?.WEIGHT,
        UNITS: val?.UNITS,
        VALUE: val?.VALUE,
      }));

    const hedgeHolding = {
      OKTA_ID: user?.sub,
      PORTFOLIO_ID: isSelectPortfolio,
    };

    const targetHedgeExposure =
      (getHedgeData?.hedgeholding?.HEDGE_PERCENT / 100) * betaValue;

    const REQUIRED_INVESTMENT =
      targetHedgeExposure /
      Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

    const getCurrRate = await mutate(apiKeys["hedgeTickerListKey"], () =>
      apiGetWithoutTokenRequest(`api/getindexticker?ticker=SH`)
    );
    handleGetCurrRateValue(getCurrRate);

    const hedgeUnits = Number(
      (
        REQUIRED_INVESTMENT / getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE
      ).toFixed(10)
    );

    const hedgeholdingData = {
      hedgeholding: {
        OKTA_ID: user?.sub,
        PORTFOLIO_ID: isSelectPortfolio,
        HEDGE_TICKER_ID: getHedgeData?.hedgeholding?.HEDGE_TICKER_ID,
        HEDGE_PERCENT: getHedgeData?.hedgeholding?.HEDGE_PERCENT,
        TOTAL_BETA_EXPOSURE: betaValue ? betaValue : 0,
        HEDGED_EXPOSURE: totalHedgeExpo ? totalHedgeExpo : totalHedgeExpo,
        UNHEDGED_EXPOSURE: totalUnhedgeExpo ? totalUnhedgeExpo : 0,
        REQUIRED_INVESTMENT: REQUIRED_INVESTMENT,
        HEDGED_TOTAL: hedgeTotal,
        UNHEDGED_TOTAL: totalPortfolio,
        HEDGED_UNITS: hedgeUnits,
      },
    };

    const builderData = {
      holdings: [...holdings],
      hedgeholding: hedgeHolding,
    };

    const {
      data: addNewHolding,
      status: statusAddNewHolding,
      errors: errorsAddNewHolding,
    } = await mutate(
      apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
      () => apiPostRequest(`api/saveHoldings`, JSON.stringify(builderData)),
      { revalidate: true }
    );

    await mutate(
      apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
      apiPostRequest(`api/hedgeholdings`, JSON.stringify(hedgeholdingData)),
      { revalidate: true }
    );

    if (!isEmpty(addNewHolding) && !addNewHolding?.error) {
      await mutate(
        apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
        () =>
          apiGetRequest(
            `api/getholdings?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
          ),
        { revalidate: true }
      );

      await mutate(apiKeys["graphData"](user?.sub, isSelectPortfolio), () =>
        apiGetRequest(
          `/api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
        )
      );

      handleLoaderShow(false);
      handleIsAlert({
        open: true,
        message: "Your holdings have been updated.",
        error: false,
      });
      handleChangeEditLabel(false);
      handleIsHoldings(false);
      handleEditHolding(false);
      setEditHoldingModal((prev) => !prev);
      handleSaveHoldings(false);
      localStorage.removeItem("builderData");
      localStorage.removeItem("percentage");
    } else if (addNewHolding?.error) {
      handleLoaderShow(false);
      handleIsAlert({
        open: true,
        message: "Network Error",
        error: true,
      });
    }
  };

  const handleHedgeRebalance = async () => {
    setModal((prev) => !prev);
    handleShowModal(true);
    handleRebalanceNew(true);
    handleTableData(getHedgeData?.holdings);
    handleRecall(true);
    handleEditModal();
  };

  const handleHedgeModify = async () => {
    setModal((prev) => !prev);
    handleHedgeModal(false);
    handleModifyHedge(true);
    handleNewInverseEtf(true);
    handleUpdateModifyHedge(true);
    handleIsModifyHedge(true);
    handleSelectIndex(getHedgeData?.hedgeholding?.INDEX_NAME);
    handleResetCode(false);
    localStorage.setItem("percentage", hedgePercentage);
  };

  return (
    <>
      <div className="flex flex-col items-stretch justify-between">
        <LineTitle text="Original Hedge" container={false} />
        <div className="grid grid-cols-1 items-end gap-x-20 gap-y-32 pt-12 lg:grid-cols-4">
          <div className="flex flex-col justify-between self-stretch">
            <Tooltip
              info="The date your hedge was created or rebalanced"
              size="lg"
            >
              <div className="text-2xs">Date Created</div>
            </Tooltip>
            <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
              {formatToSlashDate(
                !isEmpty(getHedgeData)
                  ? getHedgeData?.hedgeholding?.CREATED_DATE
                  : 0
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between self-stretch">
            <Tooltip
              info="A hypothetical investment reflecting your index and multiple level choices"
              size="lg"
            >
              <div className="text-2xs">Inverse ETF</div>
            </Tooltip>
            <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
              {!investmentLoader ? (
                `${getHedgeData?.hedgeholding?.MULTIPLIER}x${" "}${
                  getHedgeData?.hedgeholding?.INDEX_NAME
                }`
              ) : (
                <SingleLoader />
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between self-stretch">
            <Tooltip info="The original dollar value of your hedge" size="lg">
              <div className="text-2xs">Hedge $</div>
            </Tooltip>
            <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
              {`$${formatDecimalNumber(
                getHedgeData?.hedgeholding?.REQUIRED_INVESTMENT
              )}`}
            </div>
          </div>
          <div className="flex flex-col justify-between self-stretch">
            <Tooltip
              info="The original percent of your holdings you selected to hedge"
              size="lg"
            >
              <div className="text-2xs">Hedge %</div>
            </Tooltip>
            <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
              {Math.round(getHedgeData?.hedgeholding?.HEDGE_PERCENT)}%
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-stretch justify-between">
        <LineTitle text="Current Hedge Value" container={false} />
        <div className="grid grid-cols-1 items-end gap-x-20 gap-y-32 pt-12 lg:grid-cols-3">
          <div className="flex flex-col justify-between self-stretch">
            <Tooltip
              info="The current value of your hedge (as of yesterday's close)"
              size="lg"
            >
              <div className="text-2xs">Hedge $</div>
            </Tooltip>
            <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
              {`$${formatDecimalNumber(currentHedgeValue)}`}
            </div>
          </div>
          <div className="flex flex-col justify-between self-stretch">
            <Tooltip
              info="The current percent of your holdings you are hedging (as of yesterday's close)"
              size="lg"
            >
              <div className="text-2xs">Hedge %</div>
            </Tooltip>
            <div className="mt-8 border-b border-grey-400 p-8 pl-0 font-mazzardSemiBold text-sm">
              {Math.round(!!getPercentage ? getPercentage : hedgePercentage)}%
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <button
              className={`cta btn btn-primary inline-block ${
                editHolding ? "cursor-not-allowed opacity-50" : null
              }`}
              onClick={handleModal}
              disabled={editHolding ? true : false}
            >
              Rebalance
            </button>
            <Modal
              openModal={openModal}
              handleModal={handleModal}
              setModal={setModal}
              rebalance={true}
              requireHedgeHolding={requireHedgeHolding}
              targetHedgeExposure={targetHedgeExposure}
              handleEditModal={handleEditModal}
              handleHedgeModify={handleHedgeModify}
            />

            <EditModal
              openEditHoldingModal={openEditHoldingModal}
              handleEditModal={handleEditModal}
              setEditHoldingModal={setEditHoldingModal}
              setHoldingsTitle={setHoldingsTitle}
              handleHoldingModal={handleHoldingModal}
              handleHedgeRebalance={handleHedgeRebalance}
              handleRebalancePortfolio={handleRebalancePortfolio}
              isModifingHedge={isModifingHedge}
              handleHedgeModify={handleHedgeModify}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function Modal(props) {
  const {
    openModal,
    handleModal,
    setModal,
    requireHedgeHolding,
    targetHedgeExposure,
    handleEditModal,
    handleHedgeModify,
  } = props;

  const { user } = useAuth0();

  const {
    getHedgeData,
    betaValue,
    hedgePercentage,
    currentHedgeValue,
    isSelectPortfolio,
    handleModifyHedge,
    handleHedgeModal,
    handleNewInverseEtf,
    handleIsAlert,
    handleShowModal,
    handleRebalanceNew,
    handleTableData,
    handleUpdateModifyHedge,
    handleIsModifyHedge,
    handleSelectIndex,
    handleRecall,
    handleResetCode,
    editRebalance,
  } = useHedgingContext();

  const newRequiredInvestment = requireHedgeHolding - currentHedgeValue;
  const isCheck = requireHedgeHolding - currentHedgeValue;

  const handleHedgeRebalance = async () => {
    setModal((prev) => !prev);
    handleShowModal(true);
    handleRebalanceNew(true);
    handleTableData(getHedgeData?.holdings);
    handleRecall(true);

    // const rebalanceData = {
    //   OKTA_ID: user?.sub,
    //   PORTFOLIO_ID: isSelectPortfolio,
    //   HEDGED_UNITS:
    //     requireHedgeHolding / getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE,
    // };

    // await mutate(
    //   apiKeys["singleHolding"](user?.sub, isSelectPortfolio),
    //   () => apiPostRequest(`api/hedgeRebalance`, JSON.stringify(rebalanceData)),
    //   { revalidate: true }
    // );

    // await mutate(apiKeys["graphData"](user?.sub, isSelectPortfolio), () =>
    //   apiGetRequest(
    //     `/api/getperformancemonitor?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
    //   )
    // );

    // handleIsAlert({
    //   open: true,
    //   message: "Your hedge has been updated.",
    //   error: false,
    // });
  };

  return openModal ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[464px] w-[78vw] bg-white py-2 lg:w-[464px]">
          <div className="px-24 py-24 md:pt-72">
            <div className="h4 mb-24 font-mazzardSemiBold text-dark">
              Rebalance Hedge
            </div>
            <div className="mb-24">
              <div className="grid grid-cols-3 gap-16 bg-grey-200 px-8 py-12 font-mazzardSemiBold">
                <div></div>
                <div>Current</div>
                <div>New</div>
              </div>
              <div className="grid grid-cols-3 grid-rows-3 gap-16 border-b border-grey-400 px-8 py-24">
                <div className="font-mazzardSemiBold">Inverse ETF</div>
                <div>{`${getHedgeData?.hedgeholding?.MULTIPLIER}x ${getHedgeData?.hedgeholding?.INDEX_NAME}`}</div>
                <div className="font-mazzardSemiBold">
                  {`${getHedgeData?.hedgeholding?.MULTIPLIER}x ${getHedgeData?.hedgeholding?.INDEX_NAME}`}
                </div>
                <div className="font-mazzardSemiBold">Hedge %</div>
                <div>{Math.round(hedgePercentage)}%</div>
                <div className="font-mazzardSemiBold">
                  {Math.round(getHedgeData?.hedgeholding?.HEDGE_PERCENT)}%
                </div>
                <div className="font-mazzardSemiBold">Hedge $</div>
                <div>{`$${formatDecimalNumber(currentHedgeValue)}`}</div>
                <div className="font-mazzardSemiBold">
                  {`$${formatDecimalNumber(requireHedgeHolding)}`}
                </div>
              </div>
              <div className="grid grid-cols-3 gap-16 px-8 py-12">
                <div className="font-mazzardSemiBold">Summary</div>
                <div className="col-span-2">
                  {Number(
                    formatDecimalNumber(Math.abs(newRequiredInvestment))
                  ) === 0 ? (
                    "No Rebalance required."
                  ) : (
                    <HF>{`${
                      isCheck < 0 ? "Sell" : "Purchase"
                    } <span class="font-mazzardSemiBold">$${formatDecimalNumber(
                      Math.abs(newRequiredInvestment)
                    )}</span> worth of <span class="font-mazzardSemiBold">${
                      getHedgeData?.hedgeholding?.MULTIPLIER
                    }x${" "}${
                      getHedgeData?.hedgeholding?.INDEX_NAME
                    }</span> to bring Hedge % back
                    to <span class="font-mazzardSemiBold">${Math.floor(
                      getHedgeData?.hedgeholding?.HEDGE_PERCENT
                    )}%</span>`}</HF>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-16">
              <button
                className={`cta btn btn-primary w-full ${
                  Number(
                    formatDecimalNumber(Math.abs(newRequiredInvestment))
                  ) === 0
                    ? "btn-primary cursor-not-allowed cursor-not-allowed text-white opacity-50"
                    : null
                }`}
                // onClick={handleHedgeRebalance}
                // onClick={editRebalance ? handleEditModal : handleHedgeRebalance}
                onClick={handleEditModal}
                disabled={
                  Number(
                    formatDecimalNumber(Math.abs(newRequiredInvestment))
                  ) === 0
                }
              >
                Rebalance
              </button>
              <button
                className="cta btn btn-secondary w-full"
                // onClick={handleEditModal}
                onClick={handleHedgeModify}
              >
                Modify Hedge
              </button>
              {/* <button
                className="cta btn btn-secondary w-full"
                onClick={handleModal}
              >
                Cancel
              </button> */}
            </div>
            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => handleModal()}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function EditModal(props) {
  const {
    openEditHoldingModal,
    handleEditModal,
    setEditHoldingModal,
    handleHoldingModal,
    handleHedgeRebalance,
    handleRebalancePortfolio,
    isModifingHedge,
  } = props;

  const { updateDropLabel, loaderShow } = useHedgingContext();

  return openEditHoldingModal || isModifingHedge ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
        <div className="relative grid min-h-[348px] w-[78vw] bg-white py-2 lg:w-[464px]">
          <div className="px-30 pb-24 pt-56">
            <div className="text-md">
              Save your changes to <strong>{updateDropLabel}</strong> or create
              a new holding list based on changes made.
            </div>
            <button
              className="cta btn btn-primary mt-24 inline-block w-full"
              onClick={handleRebalancePortfolio}
            >
              {loaderShow ? <ButtonLoader /> : "Save"}
            </button>
            <button
              className="cta btn btn-secondary mt-24 inline-block w-full"
              onClick={handleHedgeRebalance}
            >
              Create New
            </button>

            <div className="absolute right-0 top-0 p-20">
              <button
                className="rounded-xl border border-solid border-black"
                onClick={() => handleEditModal()}
                aria-label="Close modal"
              >
                <Icon
                  name="close"
                  className={clsx(
                    "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Rebalancing;
