import { useState, useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

import "@/styles/root.scss";

export default function App({ Component, pageProps }) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const domain = isClient
    ? window.location.host.includes("pre-prod")
      ? "https://dev-35yuft2k.us.auth0.com"
      : window.location.host.includes("prod")
      ? "https://prd-35yuft2k.us.auth0.com"
      : "https://int-35yuft2k.us.auth0.com"
    : null;

  const clientId = isClient
    ? window.location.host.includes("pre-prod")
      ? process.env.NEXT_PUBLIC_AUTH0_PRE_PROD_CLIENT_ID
      : window.location.host.includes("prod")
      ? process.env.NEXT_PUBLIC_AUTH0_PROD_CLIENT_ID
      : process.env.NEXT_PUBLIC_AUTH0_INT_CLIENT_ID
    : null;

  return isClient ? (
    <main>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        // after login redirect to same page
        authorizationParams={{
          // redirect_uri: `${
          //   window.location.host.includes("netlify.app")
          //     ? `${window.location.origin}/home`
          //     : `${window.location.href}`
          // }`,
          redirect_uri: `${
            window.location.host.includes("localhost")
              ? `${window.location.origin}/hedge-builder`
              : window.location.host.includes("netlify.app")
              ? `${window.location.origin}/home`
              : `${window.location.href}`
          }`,
        }}
        // old login authorizationParams

        // authorizationParams={{
        //   redirect_uri: `${window.location.origin}/${
        //     window.location.host.includes("netlify.app")
        //       ? "home"
        //       : "hedge-builder"
        //   }`,
        // }}
        cacheLocation="localstorage"
        useRefreshTokens
      >
        <Component {...pageProps} />
      </Auth0Provider>
    </main>
  ) : null;
}
