"use client";

import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useWindowSize } from "react-use";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR, { mutate } from "swr";
import { useHedgingContext } from "@/context/betaContext";
import clsx from "clsx";

import isEmpty from "lodash.isempty";
import get from "lodash.get";
import map from "lodash.map";
import orderBy from "lodash.orderby";
import Image from "next/image";

import { EMAIL_REGEX, apiKeys, NAME_REGEX } from "@/lib/regex";
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPostWithoutTokenRequest,
  apiPutRequest,
} from "@/lib/api";
import config from "@/config";

import { Link, Icon, AlertBox } from "./*";

const BASE_CLASS = "site-header";

function SiteHeader(props) {
  let { className = "", brandLogo, navigationLinks, rightNavLinks } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setModal] = useState(false);
  const { isAlert } = useHedgingContext();
  const desktop = get(useWindowSize(), "width") > 1200 ? true : false;

  const router = useRouter();
  const shouldFetch = router.isReady;
  const currentPath =
    shouldFetch && `/${get(router, "query.stencil")?.join("/")}`;

  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleModal = () => {
    setModal((prev) => !prev);
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      const debounce = (fn) => {
        let frame;

        return (...params) => {
          if (frame) {
            cancelAnimationFrame(frame);
          }

          frame = requestAnimationFrame(() => {
            fn(...params);
          });
        };
      };

      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
      };

      document.addEventListener("scroll", debounce(storeScroll), {
        passive: true,
      });

      storeScroll();

      return () => {
        document.removeEventListener("scroll", debounce(storeScroll), {
          passive: true,
        });
      };
    }
  });

  // after login redirect
  // before : lighthouse issue
  // useEffect(() => {
  //   if (typeof window !== undefined) {
  //     let isPageReload = false;

  //     window.addEventListener("beforeunload", function (event) {
  //       // Check if the event is due to a page reload
  //       if (!isPageReload) {
  //         // Clear localStorage when the browser is closed
  //         localStorage.removeItem("localIdToken");
  //       }
  //     });

  //     window.addEventListener("unload", function () {
  //       // Set the flag to true if the page is reloaded
  //       isPageReload = true;
  //     });

  //     return () => {
  //       window.removeEventListener("beforeunload", null);
  //       window.removeEventListener("unload", null);
  //     };
  //   }
  // }, []);

  // after : lighthouse issue
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handlePageHide = (event) => {
        // Check if the page is being closed, not navigated or refreshed
        if (event.persisted === false) {
          localStorage.removeItem("localIdToken");
        }
      };
      // Add event listener for pagehide
      window.addEventListener("pagehide", handlePageHide);
      return () => {
        window.removeEventListener("pagehide", handlePageHide);
      };
    }
  }, []);

  return (
    <>
      {isOpen ? <div className="backdrop"></div> : null}
      <header className="sticky top-0 z-[1020]">
        <div
          className={clsx(
            BASE_CLASS,
            className,
            "relative border-b-4 border-secondary bg-grey-200"
          )}
        >
          <div className="container flex items-center justify-between py-16 xl:py-0">
            <div className="grid grid-cols-12 gap-16 transition-all duration-500 ease-in-out">
              <div className="col-span-12 flex items-center gap-56 xl:col-span-7">
                {get(brandLogo, "link") ? (
                  <Link href={brandLogo.link}>
                    <Image
                      src={brandLogo?.image?.url}
                      alt={brandLogo?.image?.altText}
                      width={111}
                      height={49}
                    />
                  </Link>
                ) : null}
                <nav className="hidden xl:block">
                  {!isEmpty(navigationLinks) ? (
                    <ul className="hidden items-center gap-16 xl:flex">
                      {navigationLinks?.map((menu, index) => {
                        return (
                          <MenuItems
                            items={menu}
                            key={index}
                            index={index}
                            currentPath={currentPath}
                          />
                        );
                      })}
                    </ul>
                  ) : null}
                </nav>
              </div>
              {!isEmpty(rightNavLinks) ? (
                <div className="col-span-12 my-auto hidden xl:col-span-5 xl:block">
                  <RightLinks
                    rightLinks={rightNavLinks}
                    currentPath={currentPath}
                    handleModal={handleModal}
                    openModal={openModal}
                  />
                </div>
              ) : null}
            </div>
            <HamburgerMenu isOpen={isOpen} handleMenuOpen={handleMenuOpen} />
          </div>
          <div className="container">
            <Mobile {...props} isOpen={isOpen} />
          </div>
          {!desktop && <AlertBox {...isAlert} />}
        </div>
      </header>
    </>
  );
}

function Mobile(props) {
  let { navigationLinks, rightNavLinks, currentPath, isOpen } = props;
  const desktop = get(useWindowSize(), "width") > 1200 ? true : false;

  return (
    <div
      className={clsx("absolute left-0 right-0 block bg-grey-200 py-24", {
        block: isOpen && !desktop,
        hidden: !isOpen,
      })}
    >
      <div className="container">
        <ul className="grid-rows-auto grid items-start gap-36 border-b border-purple-200 pb-32 xl:items-center">
          {navigationLinks?.map((menu, index) => {
            return (
              <MenuItems
                items={menu}
                key={index}
                index={index}
                currentPath={currentPath}
              />
            );
          })}
        </ul>
        {!isEmpty(rightNavLinks) ? (
          <RightLinks rightLinks={rightNavLinks} currentPath={currentPath} />
        ) : null}
      </div>
    </div>
  );
}

function MenuItems(props) {
  const { items, index, currentPath } = props;
  const [dropdown, setDropdown] = useState(false);
  const desktop = get(useWindowSize(), "width") > 1200 ? true : false;
  const compareLink =
    currentPath &&
    (currentPath?.slice(1) === items?.link?.href ||
      currentPath?.slice(1) ===
        items?.link?.href.slice(items?.link?.href.lastIndexOf("/") + 1));

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const closeDropdown = () => {
    setDropdown((prev) => (prev ? false : true));
  };

  return get(items, "link.href") ? (
    <li
      className="text-body-xs relative font-mazzardSemiBold"
      onMouseEnter={() => (desktop ? onMouseEnter() : null)}
      onMouseLeave={() => (desktop ? onMouseLeave() : null)}
      onClick={() => (desktop ? closeDropdown() : null)}
    >
      <div
        className="py-0 xl:py-16"
        role="button"
        aria-haspopup="menu"
        aria-expanded={dropdown ? "true" : "false"}
        onClick={() => setDropdown((prev) => !prev)}
      >
        <Link
          href={items?.link?.href}
          className={clsx("text-body-xs flex items-center gap-8", {
            "text-purple-100": compareLink,
            "text-dark": !compareLink,
          })}
        >
          <span
            className={clsx(
              "flex h-24 w-24 items-center justify-center rounded-full border p-4",
              {
                "bg-purple-100 text-white": compareLink,
                "border-grey-700 bg-transparent text-grey-700": !compareLink,
              }
            )}
          >
            {index + 1}
          </span>
          <span>{items.name}</span>
        </Link>
      </div>
      <Dropdown items={items} dropdown={dropdown} />
    </li>
  ) : null;
}

function RightLinks(props) {
  const { rightLinks, currentPath, handleModal, openModal } = props;
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
    user,
    isAuthenticated,
  } = useAuth0();
  const router = useRouter();
  const desktop = get(useWindowSize(), "width") > 1200 ? true : false;

  const {
    handleGetHedgeData,
    handleAllPortfolio,
    isSelectPortfolio,
    handleDeletePortfolioId,
    handleIdToken,
    handleAddNewRow,
    isNewRow,
    handleTableData,
    tableData,
    handleIsHoldings,
    isAlert,
    handleIsAlert,
    handleUserProfile,
    userProfile,
    isHoldings,
    handleSelectPortfolio,
    handleUpdateDropLabel,
    editHolding,
    isLoginTableData,
    handleIsLoginTableData,
    handleGetHoldingLoader,
    handleDisableButton,
    getHedgeData: getHedgeDataContext,
  } = useHedgingContext();

  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [oktaId, setOktaId] = useState(null);
  const [portfolioId, setPortfolioId] = useState(null);
  const [dropLogout, setDropLogout] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [holdingState, setHoldingState] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editableHoldingName, setEditableHoldingName] = useState("");
  const [getHedgeData, setGetHedgeData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1); // State to track which item is being edited
  const [loggedPortfolioId, setLoggedPortfolioId] = useState(0);
  const [errorList, setErrorList] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [updatedData, setUpdatedData] = useState({});
  const [hasRun, setHasRun] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const profileDropdown = useRef(null);
  const editRef = useRef(null);
  const deleteRef = useRef(null);

  const handleDropLogout = () => {
    setDropLogout(!dropLogout);
  };

  const handleDelete = (name, id) => {
    handleModal();
    setHoldingState(name);
    handleDeletePortfolioId(id);
  };

  const handleEdit = (holdingName, index) => {
    setEditableHoldingName(holdingName);
    setIsEditing(true);
    setEditingIndex(index);
  };

  const handleInputField = (e) => {
    setEditableHoldingName(e.target.value);
  };

  const setLocalIdToken = (val) => {
    localStorage.setItem("localIdToken", val);
  };

  // Edit Portfolio
  const handleSave = async (id) => {
    // You can handle saving the edited data here
    setIsEditing(false);
    setEditingIndex(-1);

    const postData = {
      ID: id,
      OKTA_ID: user?.sub,
      NAME: editableHoldingName,
    };

    const {
      data: updatePortfolio,
      statusCode,
      errors,
    } = await mutate(
      apiKeys["hedgePortfolioKey"],
      () => apiPutRequest("api/updateportfolio", postData),
      { revalidate: true }
    );

    if (!isEmpty(updatePortfolio) && !!updatePortfolio.NAME) {
      // alert("Portfolio updated successfully...");
      handleIsAlert({
        open: true,
        message: "Portfolio updated successfully.",
        error: false,
      });
    }
  };

  // get access token
  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;

          setOktaId(idTokenClaims.sid);
          setIdToken(idToken);
          handleIdToken(idToken);
          setLocalIdToken(idToken);
          // Use the token as needed (e.g., store in localStorage, make API requests)
        } catch (error) {
          console.error("Error fetching ID token:", error);
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, getIdTokenClaims, handleIdToken]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();

          setAccessToken(accessToken);
          // Use the access token for API calls or other secure operations
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    fetchAccessToken();
  }, [isAuthenticated, getAccessTokenSilently]);

  const portfolioName = localStorage.getItem("portfolioName");
  const token = localStorage.getItem("localIdToken");
  const isHolding = localStorage.getItem("isHolding");

  const fetchData = useCallback(async () => {
    // Early return conditions
    if (!isAuthenticated || !portfolioName || !user?.sub) {
      return;
    }
    // Unique key for this specific portfolio creation attempt
    const portfolioCreationKey = `portfolio_creation_${user?.sub}_${portfolioName}`;
    // Check if this exact portfolio creation is already in progress
    if (localStorage.getItem(portfolioCreationKey) === "true") {
      return;
    }
    try {
      // Prevent multiple simultaneous calls
      if (fetchData.isRunning) return;
      fetchData.isRunning = true;
      // Mark this specific portfolio creation as in progress
      localStorage.setItem(portfolioCreationKey, "true");
      handleGetHoldingLoader(true);
      // Retrieve builder data safely
      const getbuilderData = JSON.parse(
        localStorage.getItem("builderData") || "{}"
      );
      // Add Portfolio
      const postData = {
        OKTA_ID: user?.sub,
        NAME: portfolioName,
      };
      const { data: addPortfolio } = await mutate(
        apiKeys["hedgePortfolioKey"],
        () => apiPostRequest("api/addportfolio", JSON.stringify(postData)),
        { revalidate: true }
      );

      // Clear local storage flags
      if (addPortfolio.NAME === portfolioName) {
        localStorage.removeItem("justLogged");
        localStorage.removeItem("portfolioName");
      }
      setPortfolioId(addPortfolio?.ID);
      // Prepare holdings data
      const holdings =
        getbuilderData?.holdings?.map((val) => ({
          TICKER: val.TICKER,
          TICKER_NAME: val.TICKER_NAME,
          PRICE: val.PRICE,
          WEIGHT: val.WEIGHT,
          UNITS: val.UNITS,
          VALUE: val.VALUE,
        })) || [];

      const builderData = {
        holdings: holdings,
        hedgeholding: {
          OKTA_ID: user?.sub,
          PORTFOLIO_ID: addPortfolio?.ID,
        },
      };
      // Save Holdings
      const { data: saveholdings } = await mutate(
        apiKeys["singleHolding"](user?.sub, addPortfolio?.ID),
        () => apiPostRequest(`api/saveholdings`, JSON.stringify(builderData)),
        { revalidate: true }
      );
      // Handle Hedge Holdings if applicable
      if (getbuilderData?.hedgeHoldingData && isHolding) {
        const hedgeholdingData = {
          hedgeholding: {
            OKTA_ID: user?.sub,
            PORTFOLIO_ID: addPortfolio?.ID,
            HEDGE_TICKER_ID: getbuilderData?.hedgeHoldingData?.HEDGE_TICKER_ID,
            HEDGE_PERCENT: getbuilderData?.hedgeHoldingData?.HEDGE_PERCENT,
            TOTAL_BETA_EXPOSURE:
              getbuilderData.hedgeHoldingData?.TOTAL_BETA_EXPOSURE,
            HEDGED_EXPOSURE: getbuilderData?.hedgeHoldingData?.HEDGED_EXPOSURE,
            UNHEDGED_EXPOSURE:
              getbuilderData?.hedgeHoldingData?.UNHEDGED_EXPOSURE,
            REQUIRED_INVESTMENT:
              getbuilderData?.hedgeHoldingData?.REQUIRED_INVESTMENT,
            HEDGED_TOTAL: getbuilderData?.hedgeHoldingData?.HEDGED_TOTAL,
            UNHEDGED_TOTAL: getbuilderData?.hedgeHoldingData?.UNHEDGED_TOTAL,
            HEDGED_TYPE: getbuilderData?.hedgeHoldingData?.HEDGED_TYPE,
            HEDGED_UNITS: getbuilderData?.hedgeHoldingData?.HEDGED_UNITS,
          },
        };
        const { data: hedgeholdings } = await mutate(
          apiKeys["singleHolding"](user?.sub, addPortfolio?.ID),
          () =>
            apiPostRequest(
              `api/hedgeholdings`,
              JSON.stringify(hedgeholdingData)
            ),
          { revalidate: true }
        );
        // Fetch updated holdings
        await mutate(
          apiKeys["singleHolding"](user?.sub, addPortfolio?.ID),
          () =>
            apiGetRequest(
              `api/getholdings?oktaid=${user?.sub}&portfolioid=${addPortfolio?.ID}`
            ),
          { revalidate: true }
        );
        // Clear local storage
        localStorage.removeItem("builderData");
        localStorage.removeItem("isHoldings");
      }
    } catch (error) {
      console.error("Error in fetchData:", error);
    } finally {
      // Reset flags
      fetchData.isRunning = false;
      handleGetHoldingLoader(false);

      // Clear the portfolio creation flag
      localStorage.removeItem(
        `portfolio_creation_${user?.sub}_${portfolioName}`
      );
    }
  }, [
    handleGetHoldingLoader,
    isAuthenticated,
    isHolding,
    portfolioName,
    user?.sub,
  ]);
  // Initialize the flag
  fetchData.isRunning = false;
  // Effect for triggering portfolio creation
  useEffect(() => {
    const logged = localStorage.getItem("justLogged");

    // Prevent multiple calls
    const shouldFetch =
      user?.sub && !!token && logged === "true" && !fetchInProgressRef.current;

    if (shouldFetch) {
      const performFetch = async () => {
        if (fetchInProgressRef.current) return;

        try {
          fetchInProgressRef.current = true;
          await fetchData();
        } catch (error) {
          console.error("Fetch data error:", error);
        } finally {
          fetchInProgressRef.current = false;
          localStorage.removeItem("justLogged");
        }
      };

      performFetch();
    }
  }, [fetchData, token, user?.sub]);
  // Create a ref to track fetch in progress
  const fetchInProgressRef = useRef(false);

  const getPortfolioId = localStorage.getItem("portfolio_Id");

  const path = `${config?.baseUrl}/api/getholdings?oktaid=${
    user?.sub
  }&portfolioid=${
    !!getPortfolioId
      ? Number(getPortfolioId)
      : !isEmpty(router?.query?.portfolio_id)
      ? router?.query?.portfolio_id
      : isSelectPortfolio == ""
      ? loggedPortfolioId
      : isSelectPortfolio
  }`;

  const headers = {
    "Content-Type": "application/json",
    oktatoken: idToken,
  };

  const fetcher = (url) =>
    fetch(url, { headers }).then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      return res.json();
    });

  const { data: holding, isLoading: isLoadingHolding } = useSWR(
    isAuthenticated && !!loggedPortfolioId && !editHolding
      ? apiKeys["singleHolding"](
          user?.sub,
          !!getPortfolioId
            ? Number(getPortfolioId)
            : !isEmpty(router?.query?.portfolio_id)
            ? router?.query?.portfolio_id
            : isSelectPortfolio == ""
            ? loggedPortfolioId
            : isSelectPortfolio
        )
      : null,
    () => fetcher(path)
  );

  const path2 = `${config?.baseUrl}/api/getallportfolio?oktaid=${user?.sub}`;

  const { data: portfolioData, isLoading: isLoadingPortfolio } = useSWR(
    isAuthenticated && !!idToken ? apiKeys["hedgePortfolioKey"] : null,
    () => fetcher(path2),
    {
      revalidateIfStale: false,
      refreshInterval: 0,
    }
  );

  // const localIdToken = localStorage.getItem("localIdToken");

  // const [portfolioData, setPortfolioData] = useState(null);
  // const [isLoadingPortfolio, setIsLoadingPortfolio] = useState(true);

  // useEffect(() => {
  //   const fetchPortfolioData = async () => {
  //     if (isAuthenticated && !!idToken) {
  //       const path2 = `https://int.hedgewithetfs.com/api/getallportfolio?oktaid=${user?.sub}`;
  //       try {
  //         const portfolioResponse = await fetch(path2, {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             oktatoken: localIdToken,
  //           },
  //         });

  //         const portfolioData = await portfolioResponse.json();
  //         setPortfolioData(portfolioData);
  //       } catch (error) {
  //         console.error("Error fetching portfolio data:", error);
  //       } finally {
  //         setIsLoadingPortfolio(false);
  //       }
  //     }
  //   };

  //   fetchPortfolioData();
  // }, [isAuthenticated, idToken, user, localIdToken]);

  // get user profile
  const { data: profileDetail, isLoading: isLoadingProfileDetail } = useSWR(
    isAuthenticated && !!idToken && isEmpty(userProfile)
      ? apiKeys["profileDetail"]
      : null,
    () => apiGetRequest(`api/getprofile?oktaid=${user?.sub}`)
  );

  // --------- This flow if user is register ---------- //

  //
  const onHandleLogout = useCallback(() => {
    setDropLogout(!dropLogout);
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
    localStorage.clear();
  }, [dropLogout, logout]);

  const handleNewRow = () => {
    handleAddNewRow(true);
    const data = tableData?.filter((val) => val.ticker == "");
    handleIsHoldings(false);
    handleDisableButton(true);

    const initialData = [
      {
        ticker: "",
        ticker_name: "",
        market_close_value: "",
        weight: "",
        estUnit: "",
        value: "",
      },
    ];

    handleTableData(data.length ? data : initialData);
  };

  const handleEditModal = (bool) => {
    setEditModal((prev) => !prev);
  };

  const handleEditChange = (e) => {
    setUpdatedData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updatePassword = async () => {
    const clientId = window.location.host.includes("pre-prod")
      ? process.env.NEXT_PUBLIC_AUTH0_PRE_PROD_CLIENT_ID
      : process.env.NEXT_PUBLIC_AUTH0_PROD_CLIENT_ID;

    const domain = window.location.host.includes("pre-prod")
      ? "https://dev-35yuft2k.us.auth0.com"
      : "https://dev-dpz061bpd77swyp0.us.auth0.com";

    const body = {
      client_id: clientId,
      email: user?.email,
      connection: "Username-Password-Authentication",
    };

    const response = await apiPostWithoutTokenRequest(
      `${domain}/dbconnections/change_password`,
      body
    );

    if (!!response) {
      handleEditModal();

      if (!!response?.error) {
        handleIsAlert({
          open: true,
          error: true,
          message: response?.error,
        });
      } else {
        handleIsAlert({
          open: true,
          message: response,
          error: false,
        });
      }
    }
  };

  const validate = (obj, str) => {
    let error = {
      firstName: false,
      lastName: false,
      email: false,
    };

    if (str === "name") {
      if (!!obj?.firstName?.trim() && !NAME_REGEX.test(obj?.firstName)) {
        error.firstName = true;
        setErrorList((prev) => ({
          ...prev,
          firstName: "Enter valid First Name",
        }));
      } else {
        error.firstName = false;
        setErrorList((prev) => ({
          ...prev,
          firstName: "",
        }));
      }

      if (!!obj?.lastName?.trim() && !NAME_REGEX.test(obj?.lastName)) {
        error.lastName = true;
        setErrorList((prev) => ({
          ...prev,
          lastName: "Enter valid Last Name",
        }));
      } else {
        error.lastName = false;
        setErrorList((prev) => ({
          ...prev,
          lastName: "",
        }));
      }
    } else {
      if (!!obj?.email?.trim() && !EMAIL_REGEX.test(obj?.email)) {
        error.email = true;
        setErrorList((prev) => ({
          ...prev,
          email: "Enter valid Email",
        }));
      } else {
        error.email = false;
        setErrorList((prev) => ({
          ...prev,
          email: "",
        }));
      }
    }

    return error?.firstName || error?.lastName || error?.email ? false : true;
  };

  const updateName = async (obj) => {
    const validDetails = validate(obj, "name");

    if (!!validDetails && (!!obj?.firstName || !!obj?.lastName)) {
      const body = {
        OKTA_ID: user?.sub,
        EMAIL: obj?.firstName || userProfile?.EMAIL,
        FIRST_NAME: obj?.firstName || userProfile?.FIRST_NAME,
        LAST_NAME: obj?.lastName || userProfile?.LAST_NAME,
      };

      const response = await mutate(apiKeys["profileDetail"], () =>
        apiPutRequest("api/updateprofile", JSON.stringify(body))
      );

      setDropLogout(false);

      if (!!response?.error) {
        handleIsAlert({
          open: true,
          error: true,
          message: response?.error,
        });
      } else {
        handleUserProfile(response?.data);
        setUpdatedData({});
        handleEditModal();
        handleIsAlert({
          open: true,
          message: "Your name have been updated succesfully",
          error: false,
        });
      }
    } else {
      handleIsAlert({
        open: true,
        message: "Please enter your details apporpriately",
        error: true,
      });
    }
  };

  const updateEmail = async (obj) => {
    const validDetails = validate(obj, "email");

    if (!!validDetails && !!obj?.email) {
      const body = {
        OKTA_ID: user?.sub,
        email: obj?.email || user?.email,
      };

      const response = await apiPutRequest(
        "api/updateemail",
        JSON.stringify(body)
      );

      setDropLogout(false);

      if (!!response?.error) {
        handleIsAlert({
          open: true,
          error: true,
          message: response?.data?.data,
        });
      } else {
        setUpdatedData({});
        handleEditModal();
        handleIsAlert({
          open: true,
          message: response?.data?.data,
          error: false,
        });
        setTimeout(() => onHandleLogout(), 2000);
      }
    } else {
      handleIsAlert({
        open: true,
        message: "Please enter a valid email address",
        error: true,
      });
    }
  };

  const switchPortfolio = (id, label) => {
    handleSelectPortfolio(id);
    setDropLogout(false);
    handleUpdateDropLabel(label);
    router.push(`hedge-builder?portfolio_id=${id}`);
    handleAddNewRow(false);
  };

  // set use profile
  useEffect(() => {
    if (!isEmpty(profileDetail) && !isLoadingProfileDetail) {
      handleUserProfile(profileDetail);
    }
  }, [handleUserProfile, isLoadingProfileDetail, profileDetail]);

  // Close modals/dropdown on outside click/focus
  useEffect(() => {
    if (!dropLogout && !editModal && !openModal) return;

    function handleClick(event) {
      if (
        profileDropdown.current &&
        !profileDropdown.current.contains(event.target)
      ) {
        setDropLogout(false);
      }

      if (deleteRef.current && !deleteRef.current.contains(event.target)) {
        handleModal();
      }

      if (editRef.current && !editRef.current.contains(event.target)) {
        handleEditModal();
      }
    }

    document.addEventListener("click", handleClick, true);
    document.addEventListener("focus", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("focus", handleClick);
    };
  }, [dropLogout, editModal, handleModal, openModal]);

  const isData = isEmpty(isLoginTableData) ? holding : isLoginTableData;

  useEffect(() => {
    if (!isEmpty(holding?.holdings) && !isLoadingHolding) {
      handleGetHedgeData(isData);
    }
  }, [holding, handleGetHedgeData, isLoadingHolding, isData]);

  useEffect(() => {
    if (!isLoadingPortfolio) {
      handleAllPortfolio((prev) =>
        Array.isArray(portfolioData) ? portfolioData : prev
      );
      setLoggedPortfolioId(
        orderBy(portfolioData, ["DATE_CREATED_UPDATED"], ["desc"])?.[0]?.ID
      );
      setGetHedgeData((prev) => {
        return Array.isArray(portfolioData)
          ? orderBy(portfolioData, ["DATE_CREATED_UPDATED"], ["desc"])
          : prev;
      });
    }
  }, [portfolioData, handleAllPortfolio, isLoadingPortfolio]);

  // log user out on token expiration
  useEffect(() => {
    if (!isLoadingPortfolio && portfolioData?.data === "Unauthorized") {
      setTimeout(() => onHandleLogout(), 2000);
    }
  }, [isLoadingPortfolio, onHandleLogout, portfolioData?.data]);

  useEffect(() => {
    async function newUserProfile() {
      const body = {
        ID: "",
        OKTA_ID: user?.sub,
        FIRST_NAME: user?.nickname,
        LAST_NAME: "",
        EMAIL: user?.email,
        DATE_CREATED_UPDATED: user?.updated_at,
      };

      const { data: userData } = await mutate(apiKeys["profileDetail"], () =>
        apiPostRequest("api/addprofile", JSON.stringify(body))
      );

      if (!isEmpty(userData) && !!userData?.FIRST_NAME) {
        handleUserProfile(userData);
      }
    }

    if (
      !isEmpty(userProfile) &&
      !userProfile?.FIRST_NAME &&
      !userProfile?.OKTA_ID &&
      user?.sub &&
      !hasRun
    ) {
      newUserProfile();
      setHasRun(true);
    }
  }, [handleUserProfile, hasRun, portfolioId, user, userProfile]);

  return !isEmpty(rightLinks) ? (
    <div className="items-center xl:flex">
      {desktop ? <AlertBox {...isAlert} /> : null}
      <ul className="grid-rows-auto md-py-24 md-mb-24 mb-24 grid items-start gap-24 border-b border-l-0 border-solid border-secondary py-24 pl-0 md:mb-0 md:border-b-0 md:py-0 xl:flex xl:flex-row xl:items-center xl:border-l xl:border-r xl:px-24 xl:pt-0">
        {map(rightLinks, ({ target, href, name }, index) => {
          const compareLink =
            currentPath &&
            (currentPath?.slice(1) === href ||
              currentPath?.slice(1) === href.slice(href.lastIndexOf("/") + 1));

          return (
            <li key={index} className="inline-block text-left xl:text-center">
              <Link
                className={clsx("text-body-xs", {
                  "font-mazzardSemiBold text-purple-100": compareLink,
                  "text-dark": !compareLink,
                })}
                target={target}
                href={href}
              >
                <span className="block">{name}</span>
              </Link>
            </li>
          );
        })}
      </ul>

      {!isAuthenticated && (
        <button
          onClick={() => loginWithRedirect()}
          className="ml-24 flex items-center text-2xs"
        >
          <div className=" mr-8 inline-block h-32 rounded-full bg-white">
            <Icon name="profile" />
          </div>
          <span>Login</span>
        </button>
      )}

      {isAuthenticated && (
        <div className="relative" ref={profileDropdown}>
          <button
            type="button"
            onClick={() => {
              Array.isArray(getHedgeData) && Array.isArray(portfolioData)
                ? handleDropLogout()
                : null;
            }}
            className={clsx("flex items-center text-2xs", {
              "cursor-wait": !(
                Array.isArray(getHedgeData) && Array.isArray(portfolioData)
              ),
            })}
          >
            <div className="mx-8 inline-block h-32 rounded-full bg-white">
              <Icon name="profile" />
            </div>
            <span>
              Welcome{" "}
              <span className="font-mazzardBold text-blue-200 underline">
                {userProfile?.FIRST_NAME?.includes("@")
                  ? userProfile?.FIRST_NAME?.substring(
                      0,
                      userProfile?.FIRST_NAME?.indexOf("@")
                    )
                  : userProfile?.FIRST_NAME}
              </span>
            </span>
          </button>
          {dropLogout && (
            <div className="dropout-block shadow-lg-grey-600 absolute right-0 grid w-[345px] grid-cols-1 gap-y-24 bg-grey-200 px-24 py-16 shadow-lg sm:w-[416px]">
              <div className="flex justify-end">
                <button
                  className="rounded-xl border border-solid border-black"
                  onClick={() => setDropLogout(!dropLogout)}
                >
                  <Icon
                    name="close"
                    className={clsx(
                      "h-[24px] w-[24px] origin-center transform justify-self-end transition duration-200 ease-out"
                    )}
                  />
                </button>
              </div>
              <div>
                <div className="text-2xl">Hi, {userProfile?.FIRST_NAME}</div>
                <div className="">
                  Add, edit or delete your Hedge Builder holdings.
                </div>
              </div>
              <div className="h-px w-full border-b border-purple-200"></div>
              <div>
                <div className="font-mazzardBold">Your Holdings:</div>
                <ul
                  className={`${
                    getHedgeData?.length > 2 ? "h-[150px]" : ""
                  } overflow-auto pt-24`}
                >
                  {getHedgeData?.map(({ ID, holdingName, NAME }, index) => {
                    return (
                      <li key={index} className={index === 0 ? "" : "pt-24"}>
                        <div className="grid grid-cols-[1fr_auto]">
                          <div>
                            {isEditing && editingIndex === index ? (
                              <input
                                type="text"
                                value={editableHoldingName}
                                onChange={(e) => handleInputField(e, NAME)}
                              />
                            ) : (
                              <button
                                className="text-blue-200 underline hover:text-dark"
                                onClick={() => switchPortfolio(ID, NAME)}
                              >
                                {NAME}
                              </button>
                            )}
                          </div>
                          <div className="flex items-center gap-x-8">
                            {!isEditing || editingIndex !== index ? (
                              <button
                                type="button"
                                className="cursor-pointer"
                                onClick={() => handleEdit(NAME, index)}
                              >
                                <Icon name="edit" />
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={() => handleSave(ID)}
                                  className="cursor-pointer text-blue-200"
                                >
                                  <Icon name="check-blue" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => setIsEditing(false)}
                                  className="cursor-pointer text-blue-200"
                                >
                                  <Icon name="close" />
                                </button>
                              </>
                            )}
                            <button
                              type="button"
                              className="cursor-pointer"
                              onClick={() => handleDelete(NAME, ID)}
                            >
                              <Icon name="delete" />
                            </button>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <Link
                  href="/hedge-builder"
                  type="button"
                  className="mt-24 grid w-full grid-cols-[1fr_auto] gap-x-8 text-start text-blue-200 underline"
                  onClick={() => {
                    handleNewRow(), setDropLogout(false);
                  }}
                >
                  <span>Add new</span>
                  <Icon name="plus" />
                </Link>
                <DeleteModal
                  openModal={openModal}
                  handleModal={handleModal}
                  holdingState={holdingState}
                  ref={deleteRef}
                />
              </div>
              <div className="h-px w-full border-b border-purple-200"></div>
              <button
                type="button"
                className="flex text-blue-200 underline hover:text-dark"
                onClick={handleEditModal}
              >
                Edit Your Profile
              </button>
              <EditModal
                profileDetail={userProfile}
                openModal={editModal}
                updatePassword={updatePassword}
                updateName={updateName}
                handleModal={handleEditModal}
                errorList={errorList}
                handleEditChange={handleEditChange}
                updatedData={updatedData}
                updateEmail={updateEmail}
                ref={editRef}
              />
              <button
                type="button"
                className="flex text-blue-200 underline hover:text-dark"
                onClick={() => onHandleLogout()}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
}

function HamburgerMenu(props) {
  const { handleMenuOpen } = props;

  return (
    <div className="xl:hidden">
      <button
        type="button"
        onClick={handleMenuOpen}
        aria-label="hamburger-menu"
        id="hamburgerMenuBtn"
      >
        <span>
          <Icon name="hamburger" />
        </span>
      </button>
    </div>
  );
}

function Dropdown(props) {
  const { items, dropdown } = props;
  const desktop = get(useWindowSize(), "width") > 1200 ? true : false;
  const dropdownBlock = clsx(
    `dropdown-submenu absolute px-16 py-32 min-w-[14rem] bg-white shadow-[6px_8px_12px_0px_rgba(10,4,60,0.10)] top-56`,
    { block: dropdown && desktop, hidden: !dropdown || !desktop }
  );

  return !isEmpty(items?.subMenus) ? (
    <ul className={dropdownBlock}>
      {map(
        Array.isArray(items?.subMenus) ? items.subMenus : [items.subMenus],
        ({ innerMenus, name, link }, index) => {
          const subUrl = link?.external ? link?.externalHref : link?.href;
          return !!subUrl ? (
            <li key={index} className="pb-32 last:pb-0">
              <NextLink
                className="block px-0 font-mazzardSemiBold text-dark hover:text-blue-200"
                href={{
                  pathname: items?.link?.href,
                  query: {
                    component: subUrl,
                  },
                }}
                as={items?.link?.href}
              >
                {name}
              </NextLink>
              {!isEmpty(innerMenus) ? (
                <ul>
                  {map(
                    Array.isArray(innerMenus) ? innerMenus : [innerMenus],
                    ({ link, name }, index) => {
                      const innerUrl = link?.external
                        ? link?.externalHref
                        : link?.href;

                      return !!innerUrl ? (
                        <li
                          className="pb-32 pl-12 font-mazzardSemiBold first:pt-32 last:pb-0"
                          key={index}
                        >
                          <NextLink
                            className="block px-0 font-mazzardSemiBold text-dark hover:text-blue-200"
                            href={{
                              pathname: items?.link?.href,
                              query: {
                                component: innerUrl,
                              },
                            }}
                            as={items?.link?.href}
                          >
                            {name}
                          </NextLink>
                        </li>
                      ) : null;
                    }
                  )}
                </ul>
              ) : null}
            </li>
          ) : null;
        }
      )}
    </ul>
  ) : null;
}

// modal for delete
const DeleteModal = forwardRef((props, ref) => {
  const { openModal, handleModal, holdingState } = props;

  const router = useRouter();

  const {
    isIdToken,
    deletePortfolioid,
    handleIsAlert,
    handleDeletePortfolioId,
    handleSelectPortfolio,
    getAllPortfolio,
    handleGetHoldingLoader,
    isSelectPortfolio,
  } = useHedgingContext();

  const { user } = useAuth0();

  const isAddHoldings = async () => {
    handleModal();
    handleGetHoldingLoader(false);

    const portfolioId = getAllPortfolio[getAllPortfolio.length - 1];
    const lastDeletedId = portfolioId?.ID == deletePortfolioid;

    const options = {
      optimisticData: (portfolios) => {
        const newPortfolios = portfolios?.filter(
          (f) => f.ID !== deletePortfolioid
        );

        handleSelectPortfolio(
          orderBy(newPortfolios, ["DATE_CREATED_UPDATED"], ["desc"])?.[0]?.ID
        );

        return [...newPortfolios];
      },
      revalidate: true,
      rollbackOnError: true,
    };

    const response = await mutate(
      apiKeys["hedgePortfolioKey"],
      () =>
        apiDeleteRequest(
          `api/deleteportfolio?id=${deletePortfolioid}&oktaid=${user?.sub}`
        ),
      options
    );

    if (response?.error) {
      console.log(response?.error);
      handleIsAlert({
        open: true,
        message: "Error encountered. Please try again later.",
        error: true,
      });
    } else {
      handleIsAlert({
        open: true,
        message: "Your holdings have been deleted.",
        error: false,
      });
      // handleDeletePortfolioId();
      // handleSelectPortfolio();

      router.push(
        `hedge-builder?portfolio_id=${
          lastDeletedId
            ? getAllPortfolio[getAllPortfolio.length - 2]?.ID
            : getAllPortfolio[getAllPortfolio.length - 1]?.ID
        }`
      );
    }

    //
  };

  return openModal ? (
    <div className="fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
      <div
        ref={ref}
        className="relative grid min-h-[244px] w-[78vw] bg-white py-2 lg:w-[464px]"
      >
        <div className="px-24 pt-24 md:pt-64">
          <div className="mb-24 text-md text-dark">
            Click Delete to delete your {holdingState} permanently.
          </div>
          <div className="">
            <button
              className="cta btn btn-primary w-full"
              onClick={() => isAddHoldings()}
            >
              Delete
            </button>
          </div>
          <div className="pb-16 pt-24">
            <button
              className="cta btn btn-secondary w-full"
              onClick={() => handleModal()}
            >
              Cancel
            </button>
          </div>
          <div className="absolute right-0 top-0 p-20">
            <button
              className="rounded-xl border border-solid border-black"
              onClick={() => handleModal()}
              aria-label="Close modal"
            >
              <Icon
                name="close"
                className={clsx(
                  "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                )}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
});
DeleteModal.displayName = "DeleteModal";

// edit modal
const EditModal = forwardRef((props, ref) => {
  const {
    openModal,
    handleModal,
    updatePassword,
    updateName,
    profileDetail,
    errorList,
    handleEditChange,
    updatedData,
    updateEmail,
  } = props;
  const { user } = useAuth0();

  const [isEditing, setIsEditing] = useState(false);
  const [editFeild, setEditField] = useState("");

  const handleEditing = (feild) => {
    setEditField(feild);
    setIsEditing((prev) => !prev);
  };

  return openModal ? (
    <div className="edit fixed left-0 top-0 z-[1200] flex h-full w-full items-center justify-center bg-black/80">
      <div
        ref={ref}
        className="relative grid min-h-[244px] w-[78vw] bg-white py-2 lg:w-[464px]"
      >
        <div className="grid gap-y-24 px-24 pt-24 md:pt-44">
          <div className="grid">
            <div className="mb-24 text-md text-dark">Edit Password</div>
            <button
              className="btn btn-primary my-24 justify-self-center"
              onClick={updatePassword}
            >
              Update Password
            </button>
          </div>
          <div>
            <div className="mb-24 text-md text-dark">Edit Name</div>
            {isEditing && editFeild === "name" ? (
              <div className="mt-4">
                <div className="flex flex-col gap-16">
                  <div>
                    <label htmlFor="firstName" className="">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="block w-full rounded-r-4 border border-primary p-16 focus:shadow-md"
                      placeholder={profileDetail?.FIRST_NAME}
                      onChange={handleEditChange}
                      value={updatedData?.firstName}
                    />
                    <div className="text-error">{errorList?.firstName}</div>
                  </div>
                  <div>
                    <label htmlFor="lastName" className="">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="block w-full rounded-r-4 border border-primary p-16 focus:shadow-md"
                      placeholder={profileDetail?.LAST_NAME}
                      onChange={handleEditChange}
                      value={updatedData?.lastName}
                    />
                    <div className="text-error">{errorList?.lastName}</div>
                  </div>
                </div>
                <div className="mt-16 flex justify-center">
                  <button
                    type="button"
                    onClick={() => updateName(updatedData)}
                    className="border border-r-0 border-primary p-16 text-blue-200"
                  >
                    <Icon name="check-blue" />
                  </button>
                  <button
                    type="button"
                    onClick={handleEditing}
                    className="border border-primary p-16 text-blue-200"
                  >
                    <Icon name="close" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="rounded-8 mt-4 flex border border-primary">
                <div className="block w-full p-16 focus:shadow-md">
                  {profileDetail?.FIRST_NAME} {profileDetail?.LAST_NAME}
                </div>
                <button
                  type="button"
                  className="border-l border-primary p-16 text-blue-200"
                  onClick={() => handleEditing("name")}
                >
                  <Icon name="edit" />
                </button>
              </div>
            )}
          </div>
          <div>
            <div className="mb-24 text-md text-dark">Edit Email</div>
            {isEditing && editFeild === "email" ? (
              <div className="mt-4">
                <div className="flex flex-col gap-16">
                  <div>
                    <div className="flex">
                      <input
                        type="email"
                        name="email"
                        className="block w-full border border-primary p-16 focus:shadow-md"
                        placeholder={user?.email}
                        onChange={handleEditChange}
                        value={updatedData?.email}
                      />
                      <button
                        type="button"
                        onClick={() => updateEmail(updatedData)}
                        className="border border-l-0 border-r-0 border-primary p-16 text-blue-200"
                      >
                        <Icon name="check-blue" />
                      </button>
                      <button
                        type="button"
                        onClick={handleEditing}
                        className="border border-primary p-16 text-blue-200"
                      >
                        <Icon name="close" />
                      </button>
                    </div>
                    <div className="text-error">{errorList?.email}</div>
                  </div>
                </div>
                {/* <div className="mt-16 flex justify-center">
                  <button
                    type="button"
                    onClick={() => updateEmail(updatedData)}
                    className="border border-r-0 border-primary p-16 text-blue-200"
                  >
                    <Icon name="check-blue" />
                  </button>
                  <button
                    type="button"
                    onClick={handleEditing}
                    className="border border-primary p-16 text-blue-200"
                  >
                    <Icon name="close" />
                  </button>
                </div> */}
              </div>
            ) : (
              <div className="rounded-8 mt-4 flex border border-primary">
                <div className="block w-full p-16 focus:shadow-md">
                  {user?.email}
                </div>
                <button
                  type="button"
                  className="border-l border-primary p-16 text-blue-200"
                  onClick={() => handleEditing("email")}
                >
                  <Icon name="edit" />
                </button>
              </div>
            )}
          </div>
          <button
            className="btn btn-secondary  my-24"
            onClick={() => handleModal()}
          >
            Cancel
          </button>
          <div className="absolute right-0 top-0 p-20">
            <button
              className="rounded-xl border border-solid border-black"
              onClick={handleModal}
              aria-label="Close modal"
            >
              <Icon
                name="close"
                className={clsx(
                  "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
                )}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
});

// 4th link PDF json
// {
//   "href": "/docs/dummy.pdf",
//   "name": "Download PDF",
//   "target": "_blank"
// }

EditModal.displayName = "EditModal";

export default SiteHeader;
